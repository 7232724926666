import {
    Box,
    Flex,
    Paragraph,
    Button,
    Text as PlasticText,
    DownloadIcon,
    THEME_CONSTANTS
} from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import ScrollBox from '../../../../components/ScrollBox';
import Section from '../../../../components/Section';

export const StyledHtml = styled.div`
    ${({ theme }) => theme.components.disclosuresHtml};

    @media print {
        margin: 5mm;
    }
`;

const SimpleSection = ({ qaId, html, Text, indent = true, showHeading = true }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <Section Text={Text} qaId={qaId} indent={indent} showHeading={showHeading}>
            <Box bg={THEME_CONSTANTS.COLORS.black} pt={['xs', 'sm']} pb="md" px={['sm', 'md']}>
                <Paragraph
                    mt={showHeading ? 'initial' : ['sm', 'xs']}
                    textAlign="left"
                    pb="md"
                    data-qa-id={`${qaId}-subheading`}
                >
                    <Text path="Heading" />
                </Paragraph>
                <ScrollBox
                    tabIndex="0"
                    data-qa-id={`${qaId}-html-scrollbox`}
                    bg={THEME_CONSTANTS.COLORS.black}
                    p="sm"
                    printable
                >
                    <PlasticText>
                        <StyledHtml
                            dangerouslySetInnerHTML={{ __html: html }}
                            ref={componentRef}
                            data-qa-id={`${qaId}-html-text`}
                        />
                    </PlasticText>
                </ScrollBox>
                <Flex justifyContent="center" pt={['sm', 'md']}>
                    <Button
                        padding="0"
                        id={`${qaId}-download-button`}
                        data-qa-id={`${qaId}-download-button`}
                        onClick={handlePrint}
                        variant="textLink"
                        Icon={DownloadIcon}
                    >
                        <Text path="Button" />
                    </Button>
                </Flex>
            </Box>
        </Section>
    );
};

SimpleSection.propTypes = {
    qaId: PropTypes.string.isRequired,
    html: PropTypes.string,
    Text: PropTypes.func.isRequired,
    showHeading: PropTypes.bool,
    indent: PropTypes.bool
};

export default SimpleSection;
