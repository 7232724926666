import { Label, Box, THEME_CONSTANTS } from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import styled, { css } from 'styled-components';

export const StyledLabel = styled(Label)`
    width: auto;
    margin: 0;
    display: inline-flex;

    ${cssSystem({
        paddingLeft: ['1.1rem', null, '1.9rem'],
        paddingY: 'xs'
    })}
`;

StyledLabel.displayName = 'StyledLabel';

export const BorderedBox = styled(Box)`
    ${({ theme }) => css`
        border: 0.4rem solid ${theme.agreement.borderedBoxColor};
    `};
`;

BorderedBox.displayName = 'BorderedBox';

export const Legend = styled('legend').attrs({
    size: THEME_CONSTANTS.TEXT_SIZES.MEDIUM
})`
    ${({ theme }) => theme.components.customLegend}
`;
