/* eslint-disable react/prop-types */
/* eslint complexity: ["error", 7] */

import { Box } from '@cof/plastic-components';
import { useJourneyContext } from '../../../../data/JourneyContext';
import { useAggregatorBrandExperiment } from '../../../../hooks/useExperiment/useAggregatorBrandExperiment';

import AgreementSignSection from '../AgreementSign';
import BalanceTransferSection from '../BalanceTransfer';
import CheckDetailsSection from '../CheckDetails';
import MissingDetailsSection from '../MissingDetails';
import OtherThingsSection from '../OtherThingsSection';
import { isBTProduct } from '../../../../utilities/product';
import { withText } from '../../../../text/textStore';
import SimpleSection from '../SimpleSection';
import PrecontractInformationSection from '../../../../components/PreContractInformation';
import config from '@cof/ukweb-config/lib/clientConfig';
import { useZuko } from '../../../../utilities/useZuko';

const UsingYourCardSection = withText('Sections.UsingYourCard')(SimpleSection);
UsingYourCardSection.displayName = 'UsingYourCardSection';

const shouldShowMissingDetailsSection = ({ missingUserDetails, missingDetailsIsEnabled, isMissingDetailsEnabled }) =>
    missingUserDetails && missingDetailsIsEnabled && isMissingDetailsEnabled;

const shouldShowBTSection = ({ BTisEnabled, isBTProductValue }) => BTisEnabled && isBTProductValue;

const AgreementStages = ({ submitForm, submitEditRequest, editRequestPending, onErrors, invalidPANs, methods }) => {
    const { aggregatorExperiment } = useAggregatorBrandExperiment();
    const {
        agreementData,
        consumerName,
        productId,
        vendorCode,
        channel,
        aggregatorConsumerName,
        isMissingDetailsEnabled,
        brand
    } = useJourneyContext();

    const { definitionId, experimentVariant, missingUserDetails } = agreementData;

    const isBTProductValue = isBTProduct(agreementData);

    // set zuko up and get setFormEl
    const { setFormEl } = useZuko({
        formDefinitionId: definitionId,
        productId,
        vendorCode,
        channel,
        brand: aggregatorConsumerName !== null ? consumerName : brand,
        balanceTransfer: isBTProductValue,
        btGuarantee: agreementData.btDecision === 'Y',
        experimentVariant: experimentVariant ?? undefined
    });

    const { missingDetailsIsEnabled } = config.get('missingDetails');
    const { BTisEnabled } = config.get('BT');

    const showMissingDetailsSection = shouldShowMissingDetailsSection({
        missingUserDetails,
        missingDetailsIsEnabled,
        isMissingDetailsEnabled
    });
    const showBTSection = shouldShowBTSection({ BTisEnabled, isBTProductValue }); // Show BT section if it's a BT product (regardless of if they asked for a BT)

    return (
        <Box pt={aggregatorExperiment && consumerName === 'CLRS' && [null, 'lg']}>
            <CheckDetailsSection requestPending={editRequestPending} submitEditRequest={submitEditRequest} />

            <form
                data-qa-id="agreement-form"
                onSubmit={methods.handleSubmit(submitForm, onErrors)}
                method="post"
                ref={setFormEl}
            >
                {showMissingDetailsSection && <MissingDetailsSection />}
                {/** @todo Put BT field behind feature flag */}
                {showBTSection && (
                    <BalanceTransferSection invalidPANs={invalidPANs} experimentVariant={experimentVariant} />
                )}
                <UsingYourCardSection
                    qaId="using-your-card-section"
                    html={agreementData.disclosures.everythingYouNeedToKnow}
                />
                <PrecontractInformationSection
                    qaId="precontract-information-section"
                    html={agreementData.disclosures.precontractInformation}
                />
                <OtherThingsSection />
                <AgreementSignSection />
            </form>
        </Box>
    );
};

export default AgreementStages;
