import { useEffect } from 'react';
import config from '@cof/ukweb-config/lib/clientConfig';
import { isBTProduct } from '../../utilities/product';
import { addNewRelicPageAction } from '../../utilities/newRelic';
import { useZuko } from '../../utilities/useZuko';
import { useJourneyContext } from '../../data/JourneyContext';
import setQueryParam from '../../utilities/setQueryParams';

const useRedirectQueryParams = (submittingData, submitAgreementResponse, submitHiddenForm, reason) => {
    const {
        agreementData,
        sessionId,
        consumerName,
        productId,
        vendorCode,
        sParameter,
        channel,
        pcr,
        aggregatorConsumerName,
        brand
    } = useJourneyContext();
    const isGuarantee = agreementData.btDecision === 'Y';
    const { definitionId, experimentVariant } = agreementData;
    const { trackCompletionEvent } = useZuko({
        formDefinitionId: definitionId,
        productId,
        vendorCode,
        channel,
        brand: aggregatorConsumerName !== null ? consumerName : brand,
        balanceTransfer: isBTProduct(agreementData),
        btGuarantee: isGuarantee,
        experimentVariant: experimentVariant ?? undefined
    });

    useEffect(() => {
        // negate the if and return so its clear when the function shouldn't run
        if (!submittingData && submitAgreementResponse?.data && !reason) {
            const { raidAccessToken, applicationToken } = submitAgreementResponse.data;
            trackCompletionEvent();
            addNewRelicPageAction('Agreement completed');

            /** @todo make it clear these are links i.e. capitalOneQcUrl */
            const {
                Setup: { capitalOne: capitalOneSetup, partner: partnerSetup }
            } = config.get('externalLinks');

            if (applicationToken) {
                const redirectUrl = brand === 'capitalOne' ? capitalOneSetup : partnerSetup.replace('{partner}', brand);

                const qs = new URLSearchParams();
                setQueryParam(qs, 'token', applicationToken);
                setQueryParam(qs, 'session', sessionId);
                setQueryParam(qs, 'variant', sParameter?.substring(0, 5));
                setQueryParam(qs, 'consumerName', consumerName);
                setQueryParam(qs, 'v', vendorCode);
                setQueryParam(qs, 'pcr', pcr);

                window.location.assign(`${redirectUrl}/?${qs.toString()}`);
            } else submitHiddenForm(raidAccessToken);
        }
    }, [
        submittingData,
        submitAgreementResponse,
        reason,
        submitHiddenForm,
        trackCompletionEvent,
        brand,
        sessionId,
        sParameter,
        consumerName,
        vendorCode,
        pcr
    ]);
};

export default useRedirectQueryParams;
