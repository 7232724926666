const nonBoldVendorCodes = [
    'OS9',
    'TS9',
    'OD9',
    'TD9',
    'OX9',
    'TX9',
    'OK9',
    'TK9',
    'OE9',
    'OM9',
    'OT9',
    'TT9',
    'POC',
    'POE',
    'PCK',
    'POM',
    'PME',
    'POT'
];

const nonBoldConsumerNames = ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK', 'MSE', 'CCC'];

/**
 * Determines if the features should be bolded given the current journey
 *
 * @param {Object} param0
 * @param {string} param0.vendorCode the vendor code of the journey
 * @param {string} param0.consumerName the consumer name of the journey
 * @returns {boolean} whether the feature should be boleded or not
 *
 * @todo this should use a context to retrieve vendor code or consumer name
 */
// eslint-disable-next-line import/prefer-default-export
export const useBoldedFeature = ({ vendorCode, consumerName }) =>
    !nonBoldConsumerNames.includes(consumerName) && !nonBoldVendorCodes.includes(vendorCode);
