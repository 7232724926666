import { VisuallyHidden } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { withText } from '../../text/textStore';

const HiddenText = ({ Text, path }) => (
    <VisuallyHidden data-qa-id="hidden-text">
        <Text path={path} />
    </VisuallyHidden>
);

HiddenText.propTypes = {
    Text: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired
};

const WrappedHiddenText = withText('HiddenText')(HiddenText);

WrappedHiddenText.displayName = 'HiddenText';

export default WrappedHiddenText;
