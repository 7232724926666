import { THEME_CONSTANTS, themes } from '@cof/plastic-components';

export default {
    ...themes.oceanThemeNext.components.header,
    colors: {
        background: {
            mobile: THEME_CONSTANTS.COLORS.ocean.grey50,
            desktop: THEME_CONSTANTS.COLORS.ocean.grey50
        },
        border: {
            mobile: THEME_CONSTANTS.COLORS.ocean.grey50,
            desktop: THEME_CONSTANTS.COLORS.ocean.grey50
        }
    }
};
