/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, ModalCircledIcon, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import propTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { CHARACTERS } from '../../utilities/constants';
import HiddenText from '../HiddenText';

export const StyledIcon = styled(ModalCircledIcon)`
    ${({ theme, color }) => css`
        vertical-align: middle;
        margin-left: 0.2rem;
        ${color &&
        `svg {
            fill: ${theme.colors[color]};
        }`}
    `}
`;

export const StyledLink = styled(Link)`
    ${({ theme, color }) => css`
        color: ${color ?? theme.agreement.linkColor};
    `}
`;
StyledLink.displayName = 'Link';

const ModalLink = React.forwardRef(
    (
        {
            size = TC.FONT_SIZES.MEDIUM,
            variant = 'dark',
            onClick,
            children = undefined,
            qaId = undefined,
            color,
            iconColor = undefined
        },
        ref
    ) => (
        <StyledLink
            tabIndex="0"
            role="button"
            size={size}
            color={color}
            variant={variant}
            ref={ref}
            onClick={onClick}
            data-qa-id={qaId}
            onKeyDown={(event) => {
                if (event?.keyCode === CHARACTERS.spacebar || event?.keyCode === CHARACTERS.enter) {
                    event.preventDefault();
                    onClick(event);
                }
            }}
        >
            {children}
            <HiddenText path="PopupWindow" />

            <StyledIcon color={iconColor} />
        </StyledLink>
    )
);

ModalLink.propTypes = {
    size: propTypes.oneOf(['small', 'medium', 'large']),
    variant: propTypes.oneOf(['light', 'dark']),
    onClick: propTypes.func.isRequired,
    children: propTypes.node,
    qaId: propTypes.string,
    color: propTypes.string,
    iconColor: propTypes.string
};
ModalLink.displayName = 'ModalLink';

export default ModalLink;
