import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        heading1: COLORS.littlewoods.purple550,
        heading2: COLORS.littlewoods.black,
        iconColor: COLORS.littlewoods.purple550,
        section: {
            heading: {
                background: COLORS.global.grey100
            }
        },
        circle: {
            background: COLORS.global.grey100
        },
        otherThingsSection: {
            listIcon: COLORS.littlewoods.purple550
        },
        checkDetails: {
            editStageButtonVariant: 'textLink',
            iconColor: COLORS.littlewoods.purple550,
            stage: {
                background: COLORS.global.white
            },
            accordion: {
                variant: 'dark',
                separatorColor: COLORS.global.white
            }
        },
        background: COLORS.littlewoods.grey50,
        borderedBoxColor: COLORS.global.grey100,
        errorHeading: COLORS.littlewoods.purple550,
        error: COLORS.littlewoods.red550,
        heading3: COLORS.littlewoods.purple550,
        linkColor: COLORS.littlewoods.purple550,
        listItem: COLORS.global.black,
        listIcon: COLORS.littlewoods.purple550,
        modalHeading: COLORS.littlewoods.purple550,
        text: COLORS.global.black,
        scrollBoxBorder: COLORS.littlewoods.grey100,
        loadingSpinner: {
            background: COLORS.littlewoods.grey50,
            spinner: COLORS.littlewoods.purple550
        },
        label: {
            background: COLORS.littlewoods.grey50
        },
        outerContainer: {
            border: COLORS.littlewoods.purple550
        },
        product: {
            titleLine1: COLORS.littlewoods.purple550,
            titleLine2: COLORS.global.black,
            bulletPoints: COLORS.littlewoods.purple550,
            coloredDivider: COLORS.littlewoods.purple550,
            representativeColor: COLORS.global.black,
            heading2: COLORS.littlewoods.purple550,
            circle: COLORS.global.grey100,
            icons: COLORS.littlewoods.purple550,
            lockIconColor: COLORS.littlewoods.teal500,
            background: COLORS.global.white,
            cardName: COLORS.littlewoods.purple550
        },
        modal: {
            links: 'dark'
        },
        readFollowing: {
            background: COLORS.global.white
        },
        signatureBlock: {
            iconColor: COLORS.littlewoods.purple550,
            background: '#EBDEEC', // Colour not approved for use elsewhere, not to be added to Plastic
            border: COLORS.global.transparent,
            checkbox: COLORS.littlewoods.purple550,
            textColor: COLORS.littlewoods.black,
            textVariant: 'light'
        }
    }
};
