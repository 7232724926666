import { Box, Flex, Heading, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import styled, { css, useTheme } from 'styled-components';

import CustomPageRow from '../../../../components/CustomPageRow';
import { useJourneyContext } from '../../../../data/JourneyContext';
import ProductCaveat from './ProductCaveat';
import useFeatures from './Features';
import LegalRepresentative from './LegalRepresentative/LegalRepresentative';
import ReasonsToBelieve from '../../../../components/ReasonsToBelieve';
import { useAggregatorBrandExperiment } from '../../../../hooks/useExperiment/useAggregatorBrandExperiment';

import ProductStyling from '../../../../components/ProductStyling';

const StyledPageRow = ({ children }) => {
    const { aggregatorConsumerName } = useJourneyContext();
    const { aggregatorExperiment } = useAggregatorBrandExperiment();
    let PageRowStyling;
    if (aggregatorConsumerName === 'EXPR' && aggregatorExperiment) {
        PageRowStyling = {
            'background-image': 'linear-gradient(176.59deg, rgba(28, 38, 64, 0) 26.28%, #1C2640 89.12%)'
        };
    }
    return (
        <CustomPageRow data-qa-id="product-section" wrapperProps={{ style: PageRowStyling }}>
            {children}
        </CustomPageRow>
    );
};

StyledPageRow.propTypes = {
    children: PropTypes.array.isRequired
};

export const Content = styled.div`
    ${({ theme }) => theme.components.section.product.content};
`;

Content.displayName = 'Content';

export const FeatureContainer = styled.div`
    ${({ theme }) => theme.components.section.product.featureContainer}
    ${({ theme }) => css`
        svg {
            ${theme.breakpoints.mq.tabletMin} {
                transform: scale(1.5) translate(0rem, 0.3rem);
            }
        }
    `};
`;

FeatureContainer.displayName = 'FeatureContainer';

const OuterContainer = styled.div`
    position: relative;
    z-index: 0;
    border-radius: 8px;

    ${({ theme, $whitebackground }) => css`
        background: ${$whitebackground ? TC.COLORS.global.white : theme.agreement.product.background};
        ${theme.breakpoints.mq.tabletMin} {
            border-radius: 16px;
        }
    `};
`;
OuterContainer.displayName = 'OuterContainer';

const ContentWrapper = styled.div`
    ${({ theme }) => css`
        overflow: hidden;
        position: relative;
        padding: ${theme.space.sm};
        border-top-right-radius: 8px;

        ${theme.breakpoints.mq.mobileMin} {
            padding: ${theme.space.sm};
        }

        ${theme.breakpoints.mq.tabletMin} {
            border-top-right-radius: 16px;
            padding: ${theme.space.lg} ${theme.space.md} ${theme.space.md} ${theme.space.md};
        }
    `}
`;

const Product = () => {
    const { agreementData } = useJourneyContext();
    const { product } = agreementData;
    const { Features, featuresCount } = useFeatures();
    const theme = useTheme();

    const legalRepresentativeInline = featuresCount <= 2;

    const { lightMode } = useAggregatorBrandExperiment();

    const textColor = lightMode ? TC.COLORS.capitalOne.blue750 : theme.agreement.product.cardName;

    return (
        <StyledPageRow>
            <OuterContainer $whitebackground={lightMode}>
                <ContentWrapper>
                    <Flex
                        flexDirection={['row', 'row-reverse']}
                        flexWrap="wrap"
                        data-qa-id="circle-shape"
                        px={[0, 'lg']}
                    >
                        <ProductStyling />
                        <Box width={[1, 1 / 2]}>
                            <FeatureContainer data-qa-id="feature-wrapper">
                                <Heading
                                    px={['xs', 'sm']}
                                    pt={['md', 'lg']}
                                    level="2"
                                    fontSize={['headingLargeMobile', 'headingMediumDesktop']}
                                    color={textColor}
                                    textAlign="left"
                                    data-qa-id="feature-heading"
                                >
                                    {product.productName}
                                </Heading>
                                <Features />
                                {legalRepresentativeInline && <LegalRepresentative />}
                            </FeatureContainer>
                        </Box>
                        {!legalRepresentativeInline && <LegalRepresentative />}
                    </Flex>
                </ContentWrapper>
                <ReasonsToBelieve />
            </OuterContainer>
            <ProductCaveat />
        </StyledPageRow>
    );
};

export default Product;
