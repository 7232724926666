import { Paragraph, THEME_CONSTANTS, Box } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { useWatch } from 'react-hook-form';

import { withText } from '../../text/textStore';
import { getBTApprovedAmount, getTotalBTAmount, isGuaranteeProduct } from '../../utilities/balanceTransfer/utils';
import { AboveGuaranteedAmount, AboveMaxLimit, BTGuaranteedLabel } from './BTConditionalLabels';
import BTInput from './BTInput';
import { useJourneyContext } from '../../data/JourneyContext';

/* 
    Calculates which BT fields to show depending on form values and agreement data
*/
const useBTConditionals = () => {
    const { agreementData } = useJourneyContext();
    const btApprovedAmount = getBTApprovedAmount(agreementData);

    const maxCreditLimit = agreementData.product.offered.maxCreditLimit;

    const watchBT = useWatch({ name: 'BT' });
    const totalBTAmount = getTotalBTAmount(watchBT);

    const intMaxCreditLimit = parseInt(maxCreditLimit.toString().replace(/,/g, ''), 10);
    const aboveMaxLimit = totalBTAmount > intMaxCreditLimit;

    const btGuaranteedJourney = isGuaranteeProduct(agreementData) && btApprovedAmount != null;
    const aboveGuaranteedAmount = btGuaranteedJourney && totalBTAmount > btApprovedAmount;

    return {
        AboveMaxLimitError: aboveMaxLimit, // Above max credit limit for product error
        AboveGuaranteedAmountError: !aboveMaxLimit && aboveGuaranteedAmount, // Below max credit limit for product, but above pre-approved amount error
        BTGuaranteedJourneyLabel: btGuaranteedJourney // They have an approved amount, so show "You have £X left to transfer"
    };
};

/**
 * The Balance Transfer enabled section - shows on Balance Transfer journeys by default, and when the customer selects 'Yes' to a BT
 */
const BalanceTransferEnabled = ({ Text, invalidPANs }) => {
    const ConditionalRendering = useBTConditionals();

    return (
        <Box data-qa-id="balance-transfer-wrapper">
            <Paragraph size={THEME_CONSTANTS.TEXT_SIZES.SMALL}>
                <Text path="Paragraph2" />
            </Paragraph>

            <BTInput invalidPANs={invalidPANs} />

            {ConditionalRendering.AboveGuaranteedAmountError && <AboveGuaranteedAmount />}
            {ConditionalRendering.AboveMaxLimitError && <AboveMaxLimit />}
            {ConditionalRendering.BTGuaranteedJourneyLabel && <BTGuaranteedLabel />}
        </Box>
    );
};

BalanceTransferEnabled.propTypes = {
    Text: PropTypes.func.isRequired,
    invalidPANs: PropTypes.array.isRequired
};

const BalanceTransferEnabledWithText = withText('Sections.BalanceTransfer')(BalanceTransferEnabled);

BalanceTransferEnabledWithText.displayName = 'BalanceTransferEnabled';
export default BalanceTransferEnabledWithText;
