import { Box, Paragraph } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { withText } from '../../../../text/textStore';
import { experianProducts } from '../../../../utilities/product';
import { useTheme } from 'styled-components';
import { useJourneyContext } from '../../../../data/JourneyContext';

const ProductCaveat = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const { product } = agreementData;
    const theme = useTheme();

    if (!experianProducts.includes(product.id) && !product.isLineGuarantee) {
        return null;
    }

    return (
        <Box mt={['sm', 'md']} px={['xs', 'sm']}>
            {experianProducts.includes(product.id) && (
                <Paragraph data-qa-id="card-design-may-vary" color={theme.agreement.product.bulletPoints} pb={0}>
                    Card design may vary.
                </Paragraph>
            )}
            {product.isLineGuarantee && (
                <Paragraph data-qa-id="product-final-checks" color={theme.agreement.product.bulletPoints} pb={0}>
                    <JsxText path="Text1" />
                </Paragraph>
            )}
        </Box>
    );
};

ProductCaveat.propTypes = {
    JsxText: PropTypes.func.isRequired
};

const ProductCaveatWithText = withText('Sections.Product')(ProductCaveat);

ProductCaveatWithText.displayName = 'ProductCaveat';

export default ProductCaveatWithText;
