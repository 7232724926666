import customLegend from '../../capitalOne/componentStyles/legendStyles';
import linkButton from '../../capitalOne/componentStyles/linkButtonStyles';
import internalModal from '../../capitalOne/componentStyles/modalStyles';
import product from '../../capitalOne/componentStyles/section/product';
import checkboxStyles from './checkboxStyles';
import dataPlaybackStyles from './dataPlaybackStyles';
import disclosuresHtml from './disclosuresHtml';
import header from './headerStyles';

export default {
    disclosuresHtml,
    header,
    customLegend,
    linkButton,
    internalModal,
    section: {
        product
    },
    dataPlayback: dataPlaybackStyles,
    checkbox: checkboxStyles
};
