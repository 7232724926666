import NumberFormat from 'react-number-format';

import { withText } from '../../../../../../text/textStore';
import { isGreaterThanEqualToZero } from '../../../../../../utilities/product';
import { getVendorCodeFromUrl } from '../../../../../../utils';
import BaseFeature, { featureComponentPropTypes } from './BaseFeature';
import { useBoldedFeature } from './utils';
import { useJourneyContext } from '../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) =>
    isGreaterThanEqualToZero(agreementData.product.offered.maxCreditLimit) && !agreementData.product.isLineGuarantee;

const CreditLimitFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const vendorCode = getVendorCodeFromUrl() || agreementData?.vendorCode;
    const isBoldedFeature = useBoldedFeature({ consumerName: agreementData.consumerName, vendorCode });

    return (
        <BaseFeature>
            <JsxText
                path='Features.Feature1'
                params={{
                    MAX_CREDIT_LIMIT: (
                        <span style={{ fontWeight: isBoldedFeature ? 600 : 'inherit' }}>
                            <NumberFormat
                                value={agreementData.product.offered.maxCreditLimit}
                                displayType="text"
                                thousandSeparator
                                prefix="£"
                                suffix=" credit limit"
                            />
                        </span>
                    )
                }}
            />
        </BaseFeature>
    );
};

CreditLimitFeature.propTypes = featureComponentPropTypes;

const CreditLimitFeatureWithText = withText('Sections.Product')(CreditLimitFeature);

export default CreditLimitFeatureWithText;
