import { Box, Paragraph, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';

import { withText } from '../../../../../text/textStore';
import { useJourneyContext } from '../../../../../data/JourneyContext';
import { useAggregatorBrandExperiment } from '../../../../../hooks/useExperiment/useAggregatorBrandExperiment';

const LegalRep = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const theme = useTheme();
    const { product } = agreementData;

    const { lightMode } = useAggregatorBrandExperiment();

    return (
        <Box data-qa-id="legal-representative">
            <Paragraph
                mt={['sm', null, null, null, 'lg']}
                mb={[null, 'md']}
                mx={['xs', null, 'sm']}
                size="small"
                data-qa-id="product-representative"
                color={
                    lightMode
                        ? TC.COLORS.capitalOne.blue750
                        : theme.agreement.product.representativeColor ?? theme.agreement.product.bulletPoints
                }
            >
                <JsxText
                    path="Representative"
                    params={{
                        CREDIT_LIMIT: (
                            <NumberFormat
                                value={product.representative.creditLimit}
                                displayType="text"
                                thousandSeparator
                                prefix="£"
                            />
                        ),
                        INTEREST_RATE: (
                            <NumberFormat
                                value={product.interest.compound.purchase}
                                displayType="text"
                                decimalScale={2}
                                suffix="%"
                            />
                        ),
                        APR: (
                            <NumberFormat
                                value={product.representative.APR}
                                displayType="text"
                                decimalScale={1}
                                suffix="%"
                            />
                        )
                    }}
                />
            </Paragraph>
        </Box>
    );
};
const LegalRepWithText = withText('Sections.Product')(LegalRep);
export default LegalRepWithText;

LegalRep.propTypes = {
    JsxText: PropTypes.func.isRequired
};
