import NumberFormat from 'react-number-format';

import { withText } from '../../../../../../../text/textStore';
import { isBTProduct, isGreaterThanEqualToZero } from '../../../../../../../utilities/product';
import BaseFeature, { featureComponentPropTypes } from '../BaseFeature';
import { useJourneyContext } from '../../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) =>
    isBTProduct(agreementData) && isGreaterThanEqualToZero(agreementData.product.fees.balanceTransfer.percentage);

const BtFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    return (
        <BaseFeature qaId="balance-transfer-fee">
            <JsxText
                path='Features.Feature4'
                params={{
                    TRANSFER_FEE: (
                        <NumberFormat
                            value={agreementData.product.fees.balanceTransfer.percentage}
                            displayType="text"
                            decimalScale={1}
                            suffix="%"
                        />
                    )
                }}
            />
        </BaseFeature>
    );
};

BtFeature.propTypes = featureComponentPropTypes;

const BtFeatureWithText = withText('Sections.Product')(BtFeature);

export default BtFeatureWithText;
