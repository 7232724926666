import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    colors: {
        styledParagraph: COLORS.next.postOffice.purple750,
        styledText: COLORS.next.postOffice.purple750,
        styledTextLabel: COLORS.next.postOffice.purple750
    }
};
