import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { CHARACTERS } from '../../../utilities/constants';

/**
 * This element is added directly after the modal content to receive focus. Because it's not on the modal content, the
 * trapFocus function will immediately remove the focus and put it back on the dialog again.
 */
const FocusCatcher = styled.div.attrs(() => ({
    tabIndex: '0'
}))`
    ${({ theme }) => theme.components.internalModal.focusCatcher}
`;

const Backdrop = styled.div`
    ${({ theme }) => theme.components.internalModal.backdrop}
`;
Backdrop.displayName = 'Backdrop';

const Modal = styled.div`
    ${({ theme }) => theme.components.internalModal.container}
`;
Modal.displayName = 'Modal';

class ControlledModal extends Component {
    modalRef = createRef();

    contentToFocusOnOpeningModalRef = createRef();

    componentDidUpdate(prevProps) {
        const { isOpen, onAfterClose } = this.props;
        if (!prevProps.isOpen && isOpen) {
            // opening
            (this.contentToFocusOnOpeningModalRef.current || this.modalRef.current).focus();
            document.addEventListener('focus', this.trapFocus, true);
            ControlledModal.setScrollLockActive(true);
            ControlledModal.setNonModalContentHidden(true);
        } else if (prevProps.isOpen && !isOpen) {
            // closing
            ControlledModal.setScrollLockActive(false);
            ControlledModal.setNonModalContentHidden(false);
            document.removeEventListener('focus', this.trapFocus, true);
            onAfterClose();
        }
    }

    onKeyDown = ({ keyCode }) => {
        if (keyCode === CHARACTERS.escape) {
            const { onRequestClose } = this.props;
            onRequestClose();
        }
    };

    onClickAway = ({ target }) => {
        if (this.modalRef && this.modalRef.current.contains(target)) {
            return;
        }
        const { onRequestClose } = this.props;
        onRequestClose();
    };

    static setScrollLockActive = (scrollLockActive) => {
        if (scrollLockActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    };

    static setNonModalContentHidden = (hidden) => {
        const nonModalContent = document.querySelector('[data-hide-when-modal-open]');
        if (!nonModalContent) {
            throw new Error(
                "Accessibility Violation: Couldn't find any content to hide when opening the modal, " +
                    'this is required for accessibility. Please indicate which content to hide by adding the ' +
                    "'data-hide-when-modal-open' attribute"
            );
        }
        if (hidden) {
            nonModalContent.setAttribute('aria-hidden', 'true');
        } else {
            nonModalContent.removeAttribute('aria-hidden');
        }
    };

    trapFocus = (event) => {
        if (this.modalRef && this.modalRef.current.contains(event.target)) {
            return;
        }
        event.preventDefault();
        this.modalRef.current.focus();
    };

    render() {
        const { modalContent, isOpen, onRequestClose, ariaLabel, ariaLabelledby } = this.props;

        return (
            <>
                {isOpen &&
                    createPortal(
                        <Backdrop role="presentation" onClick={this.onClickAway} onKeyDown={this.onKeyDown}>
                            <Modal
                                data-qa-id="modal-content"
                                role="dialog"
                                aria-label={ariaLabel}
                                aria-labelledby={ariaLabelledby}
                                tabIndex="-1"
                                ref={this.modalRef}
                            >
                                {modalContent({
                                    onClose: onRequestClose,
                                    focusableElementRef: this.contentToFocusOnOpeningModalRef
                                })}
                            </Modal>
                            <FocusCatcher />
                        </Backdrop>,
                        document.body
                    )}
            </>
        );
    }
}

ControlledModal.propTypes = {
    modalContent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onAfterClose: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    /* eslint-disable */
    modalAccessibilityDescription: (props) => {
        if (!(props.ariaLabel || props.ariaLabelledby)) {
            return new Error(
                'Accessibility Violation: dialog label not provided, this is required for accessibility ' +
                    'please add a description of the contents of the modal using ariaLabel or if there is already an ' +
                    'element containing an appropriate description e.g. a h1, use that using ariaLabelledby'
            );
        }
    }
    /* eslint-enable */
};

export default ControlledModal;
