import { Box, Flex } from '@cof/plastic-components';
import styled, { css } from 'styled-components';

import { ReactComponent as AmountSVG } from './assets/Amount.svg';
import { ReactComponent as SavingsSVG } from './assets/Savings.svg';
import { ReactComponent as TrophySVG } from './assets/Trophy.svg';

import Reason from './Reason';
import { useJourneyContext } from '../../data/JourneyContext';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';

const ContentWrapper = styled(Flex)`
    ${({ theme }) => css`
        padding: ${theme.space.sm};

        ${theme.breakpoints.mq.mobileMin} {
            padding: ${theme.space.sm};
        }

        ${theme.breakpoints.mq.tabletMin} {
            padding: ${theme.space.lg} ${theme.space.md} ${theme.space.md} ${theme.space.md};
        }
    `}
`;

const StyledLine = styled.div`
    ${({ theme, customColor }) => css`
        padding-bottom: 2px;
        background: linear-gradient(135deg, #00c3b3 0%, #ffe433 100%);
        background: ${customColor ?? theme.agreement.product.coloredDivider};
    `}
`;

const ReasonsToBelieve = () => {
    const { lightMode } = useAggregatorBrandExperiment();
    const { aggregatorConsumerName } = useJourneyContext();
    const aggregatorAccentColor = {
        CK: '#008600',
        MSM: '#724398',
        EXPR: '#004691'
    };

    return (
        <Box data-qa-id="rtb-container">
            <StyledLine customColor={aggregatorAccentColor[lightMode && aggregatorConsumerName]} />
            <ContentWrapper justifyContent="center" flexDirection={['column', 'row']} mx={[0, 'xs']}>
                <Box width={[1, 1 / 3]} textAlign={['left', 'center']}>
                    <Reason Icon={TrophySVG} number={1} aggregatorAccentColor={aggregatorAccentColor} />
                </Box>
                <Box width={[1, 1 / 3]} textAlign={['left', 'center']}>
                    <Reason Icon={SavingsSVG} number={2} aggregatorAccentColor={aggregatorAccentColor} />
                </Box>
                <Box width={[1, 1 / 3]} textAlign={['left', 'center']}>
                    <Reason Icon={AmountSVG} number={3} aggregatorAccentColor={aggregatorAccentColor} />
                </Box>
            </ContentWrapper>
        </Box>
    );
};

ReasonsToBelieve.displayName = 'Reasons To Believe';

export default ReasonsToBelieve;
