import customLegend from '../../capitalOne/componentStyles/legendStyles';
import linkButton from '../../capitalOne/componentStyles/linkButtonStyles';
import internalModal from '../../capitalOne/componentStyles/modalStyles';
import product from '../../capitalOne/componentStyles/section/product';
import dataPlaybackStyles from './dataPlaybackStyles';
import disclosuresHtml from './disclosuresHtml';
import header from './headerStyles';
import finish from './section/finish';
import productAsos from './section/product';
import submitButton from './submitButton';

export default {
    disclosuresHtml,
    header,
    customLegend,
    internalModal,
    linkButton,
    submitButton,
    section: {
        product: { ...product, ...productAsos },
        finish
    },
    dataPlayback: dataPlaybackStyles
};
