import { PageRow, ErrorSummary, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';

export const mapErrors = (errors, isSubmitted) => {
    const errorsArray = [];
    const iterate = (obj, array) => {
        // eslint-disable-next-line consistent-return
        Object.values(obj).forEach((lowerObj) => {
            if (typeof lowerObj === 'object') {
                if ('ref' in lowerObj) {
                    array.push(lowerObj);
                    return array;
                }
                return iterate(lowerObj, array);
            }
        });
    };
    iterate(errors, errorsArray);
    const equalErrors = { ...errorsArray };
    const mappedErrors = Object.values(equalErrors).map((error) => ({
        message: error?.message || '',
        reference: error?.ref?.name || ''
    }));
    if (isSubmitted === true) {
        return mappedErrors;
    }
    return [];
};

export const onClickError = (reference) => {
    const domElement = document.querySelector(`[name="${reference}"]`);
    domElement.focus();
    if (reference === 'signature') {
        domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

const ErrorBox = ({ errorSummaryRef }) => {
    const {
        formState: { errors, isSubmitted }
    } = useFormContext();

    const mappedErrors = mapErrors(errors, isSubmitted);

    return (
        <>
            <PageRow data-qa-id="ErrorBox-section" style={{ padding: mappedErrors.length !== 0 ? '3.2rem' : '0rem' }}>
                <ErrorSummary
                    items={mappedErrors}
                    bg={TC.COLORS.global.white}
                    ref={errorSummaryRef}
                    onClickError={onClickError}
                />
            </PageRow>
        </>
    );
};
ErrorBox.propTypes = {
    errorSummaryRef: PropTypes.object.isRequired
};
export default ErrorBox;
