/* eslint complexity: ["error", 15] */

import { Box, LoadingSpinnerPortal } from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';
import cssSystem from '@styled-system/css';
import PropType from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { styled, useTheme } from 'styled-components';

import Header from '../../components/Header';
import CustomPageRow from '../../components/CustomPageRow';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import Heading from '../../components/Heading';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useEditSubmit } from '../../data';
import { useJourneyContext } from '../../data/JourneyContext';
import useEditQueryParams from '../../hooks/useEditQueryParams/useEditQueryParams';
import useRedirectQueryParams from '../../hooks/useRedirectQueryParams/useRedirectQueryParams';
import useTimeout from '../../hooks/useTimeout/useTimeout';
import useVerifyRedirect, { VERIFY_REDIRECT_STATE } from '../../hooks/useVerifyRedirect';
import { ERROR_TYPE, isAggregator, PREFERENCE } from '../../utilities/constants';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer/dataLayer';

import { onSubmitValidationErrors } from '../../utils';
import TechnicalError from '../TechnicalError';
import TimedOut from '../TimedOut';

import ProductSection from './Sections/Product/Product';

import ProductBackground from '../../components/ProductBackground/ProductBackground';
import BTNonGuaranteeBanner from '../../components/BTNonGuaranteeBanner/BTNonGuaranteeBanner';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';

import AgreementStages from './Sections/AgreementStages';
import useSubmitForm from '../../hooks/useSubmitForm/useSubmitForm';

import { isGuaranteeProduct, isValidRequestedBTProduct } from '../../utilities/balanceTransfer/utils';

const StyledPageRow = styled(CustomPageRow)`
    ${cssSystem({
        paddingTop: [0],
        paddingBottom: ['xs']
    })}
`;

const shouldShowErrorBox = ({ BTisEnabled, showErrorSummary }) => BTisEnabled && showErrorSummary;
const shouldShowBTLowerBox = ({ agreementData, isGuarantee }) =>
    isValidRequestedBTProduct(agreementData) && !isGuarantee;

const Agreement = ({ submitHiddenForm }) => {
    const { agreementData, consumerName, productId, vendorCode, channel, aggregatorConsumerName } = useJourneyContext();

    const timedOut = useTimeout();
    const verifyRedirectState = useVerifyRedirect();
    const [spinnerDisplayed, setSpinnerDisplayed] = useState(verifyRedirectState === VERIFY_REDIRECT_STATE.REDIRECTING);
    const [showErrorSummary, setShowErrorSummary] = useState(false);
    const { aggregatorExperiment } = useAggregatorBrandExperiment();

    const {
        submitForm,
        invalidPANs,
        submittingData,
        submitAgreementResponse,
        hasError: showSubmitAgreementError
    } = useSubmitForm({ setSpinnerDisplayed });
    const theme = useTheme();
    const errorSummaryRef = useRef(null);

    const { definitionId } = agreementData;
    const { BTisEnabled } = config.get('BT');

    const [
        submitEditRequest,
        editRequestPending,
        { error: editRequestError, response: editRequestResponse, input: editRequestInput }
    ] = useEditSubmit();

    const methods = useForm({
        mode: 'all', // sets the form to first on either the field being touched or the field being changed
        reValidateMode: 'onChange', // after first event validate on change
        shouldFocusError: false, // this is to validate the input as you type AND set to required if you click on and then click off
        defaultValues: {
            [PREFERENCE.ONLINE_STATEMENTS]: true,
            [PREFERENCE.MARKETING_CONTACT_PERMISSION]: true,
            BT: [{ cardNumber: '', amount: '' }],
            MISSING_DETAILS: {}
        }
    });

    useEffect(() => {
        if (agreementData) {
            // pushes info to the data layer anytime any of the deps change
            pushToDataLayer({
                event: EVENTS.AGREEMENT_NEW_SESSION,
                formDefinitionId: definitionId,
                productId,
                vendorCode,
                channel
            });
        }
    }, [agreementData, definitionId, productId, vendorCode, consumerName, channel, aggregatorConsumerName]);

    const isGuarantee = isGuaranteeProduct(agreementData);

    // focus the error on the page
    const focusError = () => {
        setShowErrorSummary(true);
        errorSummaryRef.current?.focus();
    };
    // when an form error occurs
    const onErrors = (errors) => {
        onSubmitValidationErrors(errors, focusError);
    };

    useEditQueryParams(editRequestResponse, editRequestInput);
    const reason = submitAgreementResponse?.data?.reason;
    useRedirectQueryParams(submittingData, submitAgreementResponse, submitHiddenForm, reason);

    useEffect(() => {
        if (!submittingData && reason) {
            // is this some weird edge case for BT? not sure what this is for
            methods.trigger('BT').then((valid) => {
                if (!valid) {
                    focusError();
                }
            });
            methods.resetField('signature');
        }
    }, [submittingData, reason, methods]);

    if (verifyRedirectState === VERIFY_REDIRECT_STATE.ERROR) {
        return <TechnicalError errorType={ERROR_TYPE.CLIENT_ERROR} />;
    }

    if (editRequestError) {
        return <TechnicalError errorType={ERROR_TYPE.EDIT_REQUEST} />;
    }

    if (showSubmitAgreementError) {
        return <TechnicalError errorType={ERROR_TYPE.SUBMIT_AGREEMENT} />;
    }

    const showErrorBox = shouldShowErrorBox({ BTisEnabled, showErrorSummary }); // Show any BT PAN/amount errors at top of page when they try to submit
    const showBTLowerBox = shouldShowBTLowerBox({ agreementData, isGuarantee }); // Show the non-guarantee banner on BT non-guarantee journeys

    return (
        <>
            {timedOut ? (
                <TimedOut theme={theme} />
            ) : (
                <Box bg={theme.agreement.background}>
                    {spinnerDisplayed && (
                        <LoadingSpinnerPortal
                            bg={theme.agreement.loadingSpinner.background}
                            color={theme.agreement.loadingSpinner.spinner}
                            domElementId="app-spinner"
                            zIndex={100}
                            LoadingSpinner={LoadingSpinner}
                        />
                    )}
                    <FormProvider {...methods}>
                        <ProductBackground>
                            <Header appForm />
                            {showErrorBox && <ErrorBox errorSummaryRef={errorSummaryRef} />}
                            <StyledPageRow
                                data-qa-id="headings"
                                mt={isAggregator(consumerName) && !aggregatorExperiment ? ['sm', null, 'md'] : 0}
                            >
                                <Heading />
                                {showBTLowerBox && <BTNonGuaranteeBanner />}
                            </StyledPageRow>
                            <Box pb={aggregatorExperiment && consumerName === 'CLRS' && [null, 'lg']}>
                                {agreementData.product && agreementData.disclosures && <ProductSection />}
                            </Box>
                        </ProductBackground>
                        {agreementData.stages && (
                            <AgreementStages
                                submitForm={submitForm}
                                submitEditRequest={(data) => {
                                    setSpinnerDisplayed(true);
                                    submitEditRequest(data);
                                }}
                                editRequestPending={editRequestPending}
                                onErrors={onErrors}
                                invalidPANs={invalidPANs}
                                methods={methods}
                            />
                        )}
                    </FormProvider>
                </Box>
            )}
        </>
    );
};

Agreement.propTypes = {
    submitHiddenForm: PropType.func.isRequired
};

Agreement.displayName = 'Agreement';

export default Agreement;
