import { THEME_CONSTANTS } from '@cof/plastic-components';
import { css } from 'styled-components';

import disclosuresHtml from '../../capitalOne/componentStyles/disclosuresHtml';

export default ({ theme }) => css`
    ${disclosuresHtml},
    h3 {
        ${theme.components.heading.small};
        color: ${theme.agreement.heading2};
    }

    section:nth-of-type(even) {
        background-color: ${THEME_CONSTANTS.COLORS.ocean.grey50};
    }
`;
