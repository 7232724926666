import { Box, InfoIcon, Text as PlasticText, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import PropType from 'prop-types';
import { useTheme } from 'styled-components';
import { withText } from '../../text/textStore';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';

const BTNonGuaranteeBanner = ({ Text }) => {
    const theme = useTheme();
    const { aggregatorExperiment, lightMode } = useAggregatorBrandExperiment();

    let boxStyles = {
        border: `2px solid ${theme.agreement.outerContainer.border}`
    };
    let textColor = theme.agreement.text;

    if (aggregatorExperiment) {
        if (!lightMode) {
            boxStyles = {
                backgroundColor: '#27314A'
            };
            textColor = TC.COLORS.global.white;
        } else {
            boxStyles = {
                backgroundColor: TC.COLORS.global.white
            };
            textColor = TC.COLORS.capitalOne.blue750;
        }
    }

    return (
        <Box
            style={boxStyles}
            padding="sm"
            borderRadius={['8px', null, '16px']}
            marginTop={['md', null, 'xl']}
            marginBottom={['-1rem', '-2rem']}
            data-qa-id="non-guarantee-banner"
        >
            <PlasticText color={textColor}>
                <InfoIcon verticalAlign="bottom" marginRight="xs" />
                <Text path="BTNonGuaranteeLine3" />
            </PlasticText>
        </Box>
    );
};

BTNonGuaranteeBanner.propTypes = {
    Text: PropType.func.isRequired
};

const BTNonGuaranteeBannerWithText = withText('Pages.Landing')(BTNonGuaranteeBanner);

BTNonGuaranteeBannerWithText.displayName = 'BTNonGuaranteeBanner';

export default BTNonGuaranteeBannerWithText;
