import { BaseLogo, Box } from '@cof/plastic-components';

import styled, { css } from 'styled-components';

import Card from '../Card';
import { useJourneyContext } from '../../data/JourneyContext';
import { experianProducts } from '../../utilities/product';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';

import { ReactComponent as ClearscoreIcon } from './aggregatorIcons/clearscore.svg';
import { ReactComponent as MSMIcon } from './aggregatorIcons/moneysupermarket.svg';
import { ReactComponent as CreditKarmaIcon } from './aggregatorIcons/creditkarma.svg';
import { ReactComponent as ExperiaIcon } from './aggregatorIcons/experian.svg';

export const ImageWrapper = styled.div`
    ${({ theme }) => theme.components.section.product.imageWrapper}
    ${({ theme }) => css`
        ${theme.breakpoints.mq.desktopMin} {
            padding: 0;
        }
    `}
`;

ImageWrapper.displayName = 'ImageWrapper';

export const StyledCardImage = styled(Card)`
    ${({ theme }) => css`
        position: relative;
        ${theme.breakpoints.mq.desktopMin} {
            width: 40rem;
        }
    `};
`;

StyledCardImage.displayName = 'StyledCardImage';

export const Circle = styled.div`
    ${({ theme, brand, customcolor }) => css`
        z-index: 0;
        background: ${customcolor ?? theme.agreement.circle.background};
        ${brand === 'asos' && theme.components.celebration.primary}
        border-radius: 50%;
        position: absolute;
        height: 27rem;
        right: -8rem;
        top: -8rem;
        width: 27rem;

        ${theme.breakpoints.mq.tabletMin} {
            height: 44rem;
            right: -13rem;
            top: -13rem;
            width: 44rem;
        }
        ${theme.breakpoints.mq.desktopMin} {
            height: 47.6rem;
            right: -13rem;
            top: -13rem;
            width: 47.6rem;
        }
    `};
`;

Circle.displayName = 'Circle';

const getStyledCardImage = (product, brand) => {
    if (experianProducts.includes(product.id)) {
        return (
            <StyledCardImage
                width={['25.5rem', null, '40rem']}
                src={`/assets/images/${product.id}-card.webp`}
                alt={product.productName}
                data-qa-id="experian-card"
            />
        );
    }
    if (brand === 'ocean') {
        return (
            <StyledCardImage
                width={['25.5rem', null, '40rem']}
                src="/assets/images/Ocean-Card-new.png"
                alt={product.productName}
                data-qa-id="ocean-card"
            />
        );
    }
    return (
        <StyledCardImage
            width={['25.5rem', null, '40rem']}
            alt={product.productName}
            data-qa-id="credit-card-image"
        />
    );
};

const AggregatorIconWrapper = styled.div`
    z-index: 1;
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;

    ${({ theme }) => css`
        ${theme.breakpoints.mq.desktopMin} {
            right: 2rem;
            top: 2rem;
        }
    `}
`;

const styledAggregatorIcon = {
    CK: (
        <BaseLogo
            RenderedLogo={CreditKarmaIcon}
            height={[32, null, 44]}
            width={[32, null, 43]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="creditkarma-icon"
            alt="creditkarma-icon"
        />
    ),
    MSM: (
        <BaseLogo
            RenderedLogo={MSMIcon}
            height={[40, null, 53]}
            width={[40, null, 53]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="moneysupermarket-icon"
            alt="moneysupermarket-icon"
        />
    ),
    CLRS: (
        <BaseLogo
            RenderedLogo={ClearscoreIcon}
            height={[20, null, 26]}
            width={[22, null, 29]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="clearscore-icon"
            alt="clearscore-icon"
        />
    ),
    EXPR: (
        <BaseLogo
            RenderedLogo={ExperiaIcon}
            height={[34, null, 60]}
            width={[40, null, 70]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="experian-icon"
            alt="experian-icon"
        />
    )
};

const ProductStyling = () => {
    const { agreementData, aggregatorConsumerName, brand } = useJourneyContext();
    const { product } = agreementData;
    const { aggregatorExperiment } = useAggregatorBrandExperiment();

    const AggregatorCircle = {
        CK: '#C5F5D0',
        MSM: 'linear-gradient(149.27deg, #E4E7F5 20.91%, #DDC1FD 81.36%)',
        EXPR: 'linear-gradient(105.55deg, rgba(99, 38, 120, 0.15) 10.49%, rgba(82, 101, 171, 0.15) 44.55%, rgba(186, 47, 125, 0.15) 80.01%)'
    };

    return (
        <Box pb="xs" pt={['xs', 'lg']} width={[1, 1 / 2]}>
            <AggregatorIconWrapper>
                {styledAggregatorIcon[aggregatorExperiment && aggregatorConsumerName]}
            </AggregatorIconWrapper>
            <Circle brand={brand} customcolor={AggregatorCircle[aggregatorExperiment && aggregatorConsumerName]} />
            <ImageWrapper>{getStyledCardImage(product, brand)}</ImageWrapper>
        </Box>
    );
};

export default ProductStyling;
