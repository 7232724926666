import { InfoPanel, Text as PlasticText, THEME_CONSTANTS, InfoIcon } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

import { withText } from '../../../text/textStore';
import { useJourneyContext } from '../../../data/JourneyContext';
import { useWatch } from 'react-hook-form';
import { getBTApprovedAmount, getTotalBTAmount } from '../../../utilities/balanceTransfer/utils';

const StyledInfoIcon = styled(InfoIcon)`
    ${({ theme }) => css`
        color: ${theme.colors.capitalOne.orange500};
    `};
`;

/**
 * The 'BT Guaranteed' Tool-Tip label.
 * Shows customer how much of their approved amount they have available left to transfer
 */
export const BTGuaranteedLabelFn = ({ JsxText }) => {
    const watchBT = useWatch({ name: 'BT' });
    const totalBTAmount = getTotalBTAmount(watchBT);

    const { agreementData } = useJourneyContext();
    const btApprovedAmount = getBTApprovedAmount(agreementData);

    return (
        <InfoPanel
            type="info"
            pt="unset"
            pb="xs"
            px={0}
            Icon={StyledInfoIcon}
            backgroundColor={THEME_CONSTANTS.COLORS.global.white}
            data-qa-id="amount-remaining-info-container"
        >
            <PlasticText>
                <JsxText
                    data-qa-id="amount-remaining-info-text"
                    path="Info2"
                    params={{
                        BTAMOUNTREMAINING: (
                            <NumberFormat
                                value={Math.max(btApprovedAmount - totalBTAmount, 0)}
                                displayType="text"
                                decimalScale={0}
                                prefix="£"
                                data-qa-id="amount-remaining-info-value"
                            />
                        ),
                        BTTOTAL: (
                            <NumberFormat value={btApprovedAmount} displayType="text" decimalScale={0} prefix="£" />
                        )
                    }}
                />
            </PlasticText>
        </InfoPanel>
    );
};

BTGuaranteedLabelFn.propTypes = {
    JsxText: PropTypes.func.isRequired
};
export const BTGuaranteedLabel = withText('Sections.BalanceTransfer')(BTGuaranteedLabelFn);
BTGuaranteedLabel.displayName = 'BT Guaranteed Help Label';

/**
 * The 'Above Maximum Amount' Error label.
 * Shows up when the customer enters a value above the product's max amount.
 */
export const AboveMaxLimitFn = ({ JsxText }) => (
    <InfoPanel
        type="info"
        pt={['sm', 'md']}
        px={0}
        Icon={StyledInfoIcon}
        backgroundColor={THEME_CONSTANTS.COLORS.global.white}
        data-qa-id="above-limit-warning-container"
    >
        <PlasticText data-qa-id="above-limit-warning-text">
            <JsxText path="Errors.TransferTooHigh" />
        </PlasticText>
    </InfoPanel>
);

AboveMaxLimitFn.propTypes = {
    JsxText: PropTypes.func.isRequired
};
export const AboveMaxLimit = withText('Sections.BalanceTransfer')(AboveMaxLimitFn);
AboveMaxLimit.displayName = 'Above Max Limit Error Label';

/**
 * The 'Above Guaranteed Amount' Error label.
 * Shows up when the customer enters a value above the guaranteed, but below max amount.
 */
const AboveGuaranteedAmountFn = ({ JsxText }) => (
    <InfoPanel
        type="info"
        pt={['sm', 'md']}
        px={0}
        Icon={StyledInfoIcon}
        backgroundColor={THEME_CONSTANTS.COLORS.global.white}
        data-qa-id="above-approved-warning-container"
    >
        <PlasticText data-qa-id="above-approved-warning-text">
            <JsxText path="Errors.TransferAboveApproved" />
        </PlasticText>
    </InfoPanel>
);

AboveGuaranteedAmountFn.propTypes = {
    JsxText: PropTypes.func.isRequired
};
export const AboveGuaranteedAmount = withText('Sections.BalanceTransfer')(AboveGuaranteedAmountFn);
AboveGuaranteedAmount.displayName = 'Above Guaranteed Amount Error Label';
