import {
    Box,
    Button,
    Flex,
    Heading,
    PageRowFullHeight,
    Text as PlasticText,
    THEME_CONSTANTS as TC
} from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { withText } from '../../text/textStore';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer/dataLayer';
import Header from '../../components/Header';
import { useJourneyContext } from '../../data/JourneyContext';
import { useTheme } from 'styled-components';

const TimedOut = ({ Text }) => {
    const { aggregatorConsumerName } = useJourneyContext();
    const theme = useTheme();

    useEffect(() => {
        pushToDataLayer({
            event: EVENTS.EXPIRY_PAGE_SHOW
        });
    }, []);

    return (
        <>
            <Header aggregatorConsumerName={aggregatorConsumerName} />
            <PageRowFullHeight wrapperBg={theme.agreement.background} data-qa-id="timed-out-page">
                <Heading
                    level="1"
                    color={theme.agreement.errorHeading}
                    textAlign={['left', null, 'center']}
                    data-qa-id="timeout-heading"
                >
                    <Text path="Title" />
                </Heading>
                <Box
                    paddingY={['md', 'lg', 'xl']}
                    maxWidth={TC.SCREEN_WIDTHS.SIX_COLUMN}
                    mx="auto"
                    data-qa-id="timed-out-text-section"
                >
                    <PlasticText
                        color={theme.agreement.text}
                        paddingY={['md', 'lg', 'xl']}
                        data-qa-id="timed-out-text1"
                    >
                        <Text path="Paragraph1" />
                    </PlasticText>
                    <br />
                    <br />
                    <br />
                    <PlasticText
                        color={theme.agreement.text}
                        paddingY={['md', 'lg', 'xl']}
                        data-qa-id="timed-out-text2"
                    >
                        <Text path="Paragraph2" />
                    </PlasticText>
                </Box>

                <Flex justifyContent="center">
                    <Button as="a" href={Text({ path: 'Button.URL' })} data-qa-id="timed-out-button">
                        <Text path="Button.Text" />
                    </Button>
                </Flex>
            </PageRowFullHeight>
        </>
    );
};

TimedOut.propTypes = {
    Text: PropTypes.func.isRequired
};

const TimedOutWithText = withText(`Pages.TimedOut`)(TimedOut);

TimedOutWithText.displayName = 'TimedOut';

export default TimedOutWithText;
