import { Heading, Box } from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import PropTypes from 'prop-types';

import { styled, useTheme } from 'styled-components';

import CustomPageRow from '../CustomPageRow';

const SectionHeading = styled(Heading)`
    background-color: #fff;
    padding-bottom: 0;
    text-align: left;
`;

const BorderRadiusBox = styled(Box)`
    ${cssSystem({
        borderRadius: ['0.4rem', null, '0.8rem']
    })}
    overflow: hidden;
`;

const Content = styled.div`
    background-color: #fff;
`;

const Section = ({ Text, children, qaId, showHeading = true, indent = true }) => {
    const theme = useTheme();

    return (
        <CustomPageRow indent={indent} data-qa-id={qaId}>
            <BorderRadiusBox>
                {showHeading && (
                    <SectionHeading
                        level="2"
                        size="small"
                        color={theme.agreement.heading2 ?? theme.agreement.heading2}
                        px={['sm', 'md', 'md']}
                        pt={['md', 'lg']}
                        pb={['sm', 'md']}
                        data-qa-id="section-heading"
                    >
                        <Text path="Title" />
                    </SectionHeading>
                )}
                <Content data-qa-id="section-content">{children}</Content>
            </BorderRadiusBox>
        </CustomPageRow>
    );
};

Section.propTypes = {
    Text: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    qaId: PropTypes.string.isRequired,
    showHeading: PropTypes.bool,
    indent: PropTypes.bool
};

export default Section;
