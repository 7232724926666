import { LockIcon } from '@cof/plastic-components';

import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';

import { withText } from '../../../../../../../text/textStore';
import { isGreaterThanEqualToZero } from '../../../../../../../utilities/product';
import BaseFeature, { featureComponentPropTypes } from '../BaseFeature';
import { getLineGuranteeCreditLimit } from './utils';
import { useJourneyContext } from '../../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) =>
    agreementData.product.isLineGuarantee &&
    isGreaterThanEqualToZero(getLineGuranteeCreditLimit(agreementData.product).lineGuaranteeCreditLimit);

const LineGuaranteeFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const theme = useTheme();

    return (
        <BaseFeature
            listItemProps={{ Icon: LockIcon, iconColor: theme.agreement.product.lockIconColor }}
            qaId="line-guarantee-1"
        >
            <JsxText
                path='Features.Feature6'
                params={{
                    MAX_CREDIT_LIMIT: (
                        <NumberFormat
                            value={getLineGuranteeCreditLimit(agreementData.product).lineGuaranteeCreditLimit}
                            displayType="text"
                            thousandSeparator
                            prefix="£"
                        />
                    )
                }}
            />
        </BaseFeature>
    );
};

LineGuaranteeFeature.propTypes = featureComponentPropTypes;

const LineGuranteeFeatureWithText = withText('Sections.Product')(LineGuaranteeFeature);

export default LineGuranteeFeatureWithText;
