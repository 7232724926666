import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        heading1: COLORS.thinkMoney.blue550,
        background: COLORS.global.grey50,
        borderedBoxColor: COLORS.global.grey100,
        errorHeading: COLORS.thinkMoney.blue550,
        error: COLORS.thinkMoney.red550,
        heading2: COLORS.global.black,
        heading3: COLORS.global.black,
        iconColor: COLORS.thinkMoney.blue550,
        linkColor: COLORS.global.black,
        listItem: COLORS.thinkMoney.blue550,
        modalHeading: COLORS.thinkMoney.blue550,
        text: COLORS.global.black,
        scrollBoxBorder: COLORS.global.grey300,
        section: {
            heading: {
                background: '#E3E3E0'
            }
        },
        circle: {
            background: COLORS.thinkMoney.blue550
        },
        checkDetails: {
            editStageButtonVariant: 'textLink',
            stage: {
                background: '#FFFFFF'
            },
            accordion: {
                variant: 'dark',
                separatorColor: '#FFFFFF'
            }
        },
        loadingSpinner: {
            background: COLORS.global.grey50,
            spinner: COLORS.thinkMoney.blue550
        },
        label: {
            background: COLORS.global.grey50
        },
        outerContainer: {
            border: COLORS.thinkMoney.blue550
        },
        product: {
            background: '#FFFFFF',
            coloredDivider: COLORS.thinkMoney.blue550,
            cardName: COLORS.global.black,
            titleLine1: COLORS.thinkMoney.blue550,
            titleLine2: COLORS.global.black,
            circle: COLORS.thinkMoney.blue550,
            bulletPoints: COLORS.global.black,
            icons: COLORS.thinkMoney.blue550,
            lockIconColor: COLORS.thinkMoney.yellow250
        },
        modal: {
            links: 'dark'
        },
        readFollowing: {
            background: COLORS.global.white
        },
        signatureBlock: {
            iconColor: COLORS.thinkMoney.blue550,
            background: '#E0EFF6', // Colour not approved for use elsewhere, not to be added to Plastic
            border: COLORS.global.transparent,
            checkbox: COLORS.global.black,
            textVariant: 'light'
        }
    }
};
