import config from '@cof/ukweb-config/lib/clientConfig';
import { Buffer } from 'buffer';
import PropType from 'prop-types';
import React, { useRef } from 'react';

import HiddenFormComponent from './HiddenFormComponent';

const HiddenForm = ({ children }) => {
    const submissionForm = useRef(null);
    const raidAccessToken = useRef(null);
    const iovationBlackBox = useRef(null);

    const { isEnabled } = config.get('iovation') || {};

    const submitHiddenForm = (raidToken) => {
        raidAccessToken.current.value = raidToken;
        if (isEnabled) {
            iovationBlackBox.current.value = Buffer.from(window.IGLOO.getBlackbox().blackbox).toString('base64');
        }
        submissionForm.current.submit();
    };
    return (
        <>
            {React.cloneElement(children, { submitHiddenForm })}
            <HiddenFormComponent
                submissionForm={submissionForm}
                raidAccessToken={raidAccessToken}
                iovationBlackBox={iovationBlackBox}
            />
        </>
    );
};

HiddenForm.propTypes = {
    children: PropType.node.isRequired
};

export default HiddenForm;
