import { THEME_CONSTANTS as TC } from '@cof/plastic-components';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';

const LinkButton = styled.button`
    ${({ theme, size = TC.TEXT_SIZES.MEDIUM, variant = 'dark' }) => css`
        ${theme.components.link.base}
        ${theme.components.link[variant]}
        ${theme.components.link[size]}
        ${theme.components.linkButton}
    `}
`;

LinkButton.propTypes = {
    size: propTypes.oneOf(['small', 'medium', 'large']),
    variant: propTypes.oneOf(['light', 'dark'])
};

LinkButton.displayName = 'LinkButton';

export default LinkButton;
