import { THEME_CONSTANTS as TC, lumaTheme } from '@cof/plastic-components';

export default {
    ...lumaTheme.components.header,
    colors: {
        background: {
            mobile: TC.COLORS.global.grey50,
            desktop: TC.COLORS.global.grey50
        },
        border: {
            mobile: TC.COLORS.global.grey50,
            desktop: TC.COLORS.global.grey50
        }
    }
};
