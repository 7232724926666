/* eslint-disable import/prefer-default-export */
import { mapErrors } from './components/ErrorBox/ErrorBox';
import { HOSTED_PARTNERS, PCR_DEFAULT } from './utilities/constants';
import { addNewRelicPageAction } from './utilities/newRelic';
import { trackGenericEvent } from './utilities/useZuko';
import { isBTProduct } from './utilities/product';
import encryptPANs from './utilities/encryption';

export const getSearchParameter = (parameter) => {
    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const lowerCaseParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => lowerCaseParams.append(key.toLowerCase(), value));

    return lowerCaseParams.get(parameter) || undefined; // undefined is added because query.get returns null if not found
};

export const getChannel = () => {
    const rawChannel = getSearchParameter('channel');

    return /^(email|sms|qcp|per)[0-9a-z]{0,5}$/i.test(rawChannel) ? rawChannel.toUpperCase() : 'NONE';
};

export const getSessionId = () => {
    const id = getSearchParameter('session');
    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(id)) {
        return id;
    }
    return undefined;
};

export const getPcr = (brand) => {
    const pcr = getSearchParameter('pcr');
    if (HOSTED_PARTNERS.includes(brand)) {
        return PCR_DEFAULT;
    }
    if (/^\S{5,36}$/.test(pcr)) {
        return pcr;
    }
    return undefined;
};

export const getVendorCodeFromUrl = () => {
    const validVendorCode = (vendorCode) => (/^[0-9A-Za-z]{3,}$/.test(vendorCode) ? vendorCode : undefined);

    const s = getSearchParameter('s');
    const sVendorCode = s?.substring(8, 11)?.replace('x', '');

    return validVendorCode(getSearchParameter('v')) || validVendorCode(sVendorCode);
};

export const getSParameter = () => {
    const sParameter = getSearchParameter('s');
    if (/^[0-9a-zA-Z]{11,28}$/.test(sParameter)) {
        return sParameter;
    }
    return undefined;
};

export const getSParameterCharAt = (position) => {
    const sParameter = getSParameter();
    const character = sParameter ? sParameter.charAt(position) : null;
    return character;
};

export const getProduct = () => {
    const pParameter = getSearchParameter('p');
    if (/^CCS[0-9]{2,3}$/i.test(pParameter)) {
        return pParameter;
    }
    return undefined;
};

export const onSubmitValidationErrors = (errors, focusError) => {
    if (Object.keys(errors).length === 1 && errors.signature) {
        errors.signature.ref.focus();
        errors.signature.ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
        focusError();
    }

    const mappedErrors = mapErrors(errors, true);
    mappedErrors.forEach((error) => {
        const errorMsg = `${error.reference}: ${error.message}`;
        trackGenericEvent(errorMsg);
        addNewRelicPageAction('submit-validation-error', { error: errorMsg });
    });
};

const autoExpandedCheckDetails = {
    vendorCodes: ['OS9', 'TS9', 'OD9', 'TD9', 'OX9', 'TX9', 'OK9', 'TK9', 'OE5', 'OS5', 'ABR'],
    consumerNames: ['CLRS', 'EXPR', 'CK', 'CRE', 'BRQ_RAID', 'BRQ_ASOS', 'BRQ_VERY', 'BRQ_LTWD']
};

/**
 * Determines if the check my detials accordion should be auto expanded on this journey
 *
 * @param {Object} param0
 * @param {string} param0.consumerName The consumer name of the journey
 * @param {string} param0.vendorCode The vendor code of the journey
 * @returns {boolean} represents whether the accordion should be opened
 */
export const shouldExpandCheckDetailsOnPageLoad = ({ consumerName, vendorCode }) =>
    autoExpandedCheckDetails.consumerNames.includes(consumerName) ||
    autoExpandedCheckDetails.vendorCodes.includes(vendorCode);

export const validatePANS = async ({ pans, panPublicKey, agreementData }) => {
    const ePANs = isBTProduct(agreementData) ? await encryptPANs(pans, panPublicKey) : null;
    return ePANs;
};
