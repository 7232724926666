import PropTypes from 'prop-types';

import OtherIncome from '../../../../components/MissingDetails/OtherIncome';
import PhoneNumbers from '../../../../components/MissingDetails/PhoneNumbers';
import Occupation from '../../../../components/MissingDetails/Occupation';
import Title from '../../../../components/MissingDetails/Title';
import EmailAddress from '../../../../components/MissingDetails/EmailAddress';
import Section from '../../../../components/Section';
import SectionContent from '../../../../components/SectionContent';
import { withText } from '../../../../text/textStore';
import { useJourneyContext } from '../../../../data/JourneyContext';

const MissingDetails = ({ Text }) => {
    const { agreementData } = useJourneyContext();
    const { missingUserDetails } = agreementData;

    return (
        <Section Text={Text} qaId="missing-details-section">
            <SectionContent>
                {missingUserDetails.includes('individual.title') && <Title />}
                {missingUserDetails.includes('individual.emailAddress') && <EmailAddress />}
                {missingUserDetails.includes('individual.mobilePhoneNumber') && <PhoneNumbers />}
                {missingUserDetails.includes('individual.occupation') && <Occupation />}
                {missingUserDetails.includes('individual.additionalQuestions.otherHouseholdIncome') && <OtherIncome />}
            </SectionContent>
        </Section>
    );
};

MissingDetails.propTypes = {
    Text: PropTypes.func.isRequired
};

const MissingDetailsWithText = withText('Sections.MissingDetails')(MissingDetails);

MissingDetailsWithText.displayName = 'MissingDetails';
export default MissingDetailsWithText;
