import findFormValue from '../findFormValue';
import { isBTProduct, isGreaterThanZero } from '../product';

// Get the BT amount the customer requested
export const getRequestedBTAmount = (agreementData) => {
    const requestedAmount = findFormValue('creditRequested.balanceTransfer', agreementData);
    return isGreaterThanZero(requestedAmount) ? requestedAmount : null;
};

// Checks whether the requested amount (at time of quote) of BT is valid, regardless of whether it's guaranteed or not
export const isValidRequestedBTProduct = (agreementData) => {
    const isValidBTAmount = getRequestedBTAmount(agreementData);
    const { btDecision: gotBTDecision } = agreementData;
    return isBTProduct(agreementData) && isValidBTAmount && !!gotBTDecision;
};

// Check whether the customer is on a BT Guarantee Journey
export const isGuaranteeProduct = (agreementData) => agreementData.btDecision === 'Y';

// Return the amount that the customer was approved for (BT Guarantee Journey)
export const getBTApprovedAmount = (agreementData) => {
    const requestedAmount = getRequestedBTAmount(agreementData);
    const isGuarantee = isGuaranteeProduct(agreementData);
    return isGuarantee && requestedAmount;
};

/**
 * Calculates the total value the customer is requesting across all present BT input fields
 * @param {array} watchBT - array of PAN/amount key pairs
 */
export const getTotalBTAmount = (watchBT) => {
    let totalBTAmount = watchBT?.reduce?.((accumulator, currentValue) => {
        const currentAmount = currentValue.amount;
        if (currentAmount === '' || Number.isNaN(currentAmount)) {
            return accumulator;
        }

        const intCurrentAmount = parseInt(currentAmount, 10);
        return accumulator + intCurrentAmount;
    }, 0);

    if (totalBTAmount < 0 || Number.isNaN(totalBTAmount)) {
        totalBTAmount = 0;
    }

    return totalBTAmount;
};
