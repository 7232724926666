import { Button, Flex, Heading, Paragraph, EditCircledIcon } from '@cof/plastic-components';
import PropTypes, { array, func, object, string } from 'prop-types';
import { useState } from 'react';

import HiddenText from '../../components/HiddenText';
import Modal from '../../components/Modal';
import CloseButton from '../../components/Modal/CloseButton';
import { useJourneyContext } from '../../data/JourneyContext';
import { withText } from '../../text/textStore';
import { useTheme } from 'styled-components';

const EditStage = ({ Text, TemplateText, name, qaId, onProceed, disabled }) => {
    const theme = useTheme();
    const [proceed, setProceed] = useState(false);
    const { agreementData } = useJourneyContext();
    const isLineGuarantee = agreementData.product?.isLineGuarantee;
    const handleProceed = (onClose) => {
        onClose();
        setProceed(true);
    };
    const handleOpen = (onOpen) => {
        if (!disabled) {
            onOpen();
        }
    };
    const handleOnAfterClose = () => {
        if (proceed) {
            onProceed();
        }
    };
    return (
        <Modal
            onAfterClose={handleOnAfterClose}
            modalContent={({ onClose, focusableElementRef }) => (
                <div>
                    <CloseButton onClick={onClose} ref={focusableElementRef} />
                    <Heading data-qa-id="edit-stage-modal-title" level="3" textAlign="left" mb={['xs', 'sm', 'md']}>
                        <Text path="Heading" />
                    </Heading>

                    {isLineGuarantee ? (
                        <>
                            <Paragraph data-qa-id="edit-stage-modal-paragraph1">
                                <Text path="Paragraph1LineGuarantee" />
                            </Paragraph>
                            <Paragraph data-qa-id="edit-stage-modal-paragraph2">
                                <Text path="Paragraph2LineGuarantee" />
                            </Paragraph>
                        </>
                    ) : (
                        <>
                            <Paragraph data-qa-id="edit-stage-modal-paragraph1">
                                <Text path="Paragraph1" />
                            </Paragraph>
                            <Paragraph data-qa-id="edit-stage-modal-paragraph2">
                                <Text path="Paragraph2" />
                            </Paragraph>
                        </>
                    )}

                    <Flex justifyContent="center" py={['xs']}>
                        <Button
                            data-qa-id="edit-stage-modal-proceed"
                            onClick={() => {
                                handleProceed(onClose);
                            }}
                        >
                            <Text path="Proceed" />
                        </Button>
                    </Flex>
                    <Flex justifyContent="center" py={['xs']}>
                        <Button variant="secondary" onClick={onClose} data-qa-id="edit-stage-modal-return">
                            <Text path="Return" />
                        </Button>
                    </Flex>
                </div>
            )}
            modalTrigger={({ onOpen, focusableElementRef }) => (
                <Button
                    padding="0"
                    data-qa-id={qaId}
                    variant={theme.agreement.checkDetails.editStageButtonVariant}
                    onClick={() => {
                        handleOpen(onOpen);
                    }}
                    ref={focusableElementRef}
                    Icon={EditCircledIcon}
                >
                    <HiddenText path="PopupWindow" />
                    <TemplateText path="TriggerText" params={{ name }} />
                </Button>
            )}
            ariaLabel={Text({ path: 'Heading' })}
        />
    );
};

EditStage.propTypes = {
    Text: PropTypes.func.isRequired,
    TemplateText: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    qaId: PropTypes.string.isRequired,
    onProceed: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    theme: PropTypes.objectOf(PropTypes.oneOfType([array, func, object, string]))
};

const EditStageWithText = withText('Modals.EditStage')(EditStage);

EditStageWithText.displayName = 'EditStage';

export default EditStageWithText;
