import { useJourneyContext } from '../../data/JourneyContext';
import useExperiment from './useExperiment';

export const useAggregatorBrandExperiment = () => {
    const [experiment] = useExperiment();
    const { aggregatorConsumerName } = useJourneyContext();
    const aggregatorExperiment = ['O', 'P', 'R'].includes(experiment);
    const lightMode = aggregatorExperiment && aggregatorConsumerName != 'CLRS';

    return { aggregatorExperiment, lightMode };
};
