import { useBrand } from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';

import { useJourneyContext } from '../../data/JourneyContext';
import { addNewRelicPageAction } from '../../utilities/newRelic';

export const VERIFY_REDIRECT_STATE = {
    REDIRECTING: 'REDIRECTING',
    NO_ACTION: 'NO_ACTION',
    ERROR: 'ERROR'
};

/**
 * Check whether the user should be redirected to verify
 *
 * @returns {VERIFY_REDIRECT_STATE} contains the result of the redirect
 */
const useVerifyRedirect = () => {
    const brand = useBrand();
    const { agreementData } = useJourneyContext();
    const isRedirectToVerifyEnabled = config.get('redirectToVerify')[brand];
    const {
        Verify: { capitalOne: capitalOneVerify, partner: partnerVerify }
    } = config.get('externalLinks');
    const verifyUrl = brand === 'capitalOne' ? capitalOneVerify : partnerVerify.replace('{partner}', brand);
    // facilitate redirect
    if (agreementData?.reason === 'UNAUTHORISED') {
        if (isRedirectToVerifyEnabled && verifyUrl) {
            addNewRelicPageAction('Redirecting to Verify');
            const urlSearchParams = new URLSearchParams(window.location.search);
            window.location.assign(`${verifyUrl}/?${urlSearchParams.toString()}`);
            return VERIFY_REDIRECT_STATE.REDIRECTING;
        }
        return VERIFY_REDIRECT_STATE.ERROR;
    }
    return VERIFY_REDIRECT_STATE.NO_ACTION;
};

export default useVerifyRedirect;
