import { Link, NewTabIcon, Paragraph } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import HiddenText from '../../../components/HiddenText';
import { withText } from '../../../text/textStore';

const AccordionItemContent = ({ JsxText, Text, path, hasLink, hasPartner, partnerName }) => {
    const answer = (
        <JsxText
            path={`${path}.Answer`}
            params={Object.assign(
                hasPartner
                    ? {
                          PARTNER: <span>{partnerName}</span>
                      }
                    : {},
                hasLink
                    ? {
                          LINK: (
                              <Link href={Text({ path: `${path}.Link.Url` })} target="_blank" rel="noopener noreferrer">
                                  <Text path={`${path}.Link.Text`} />
                                  <HiddenText path="NewTab" />
                                  <NewTabIcon verticalAlign="middle" ml="0.2rem" />
                              </Link>
                          )
                      }
                    : {}
            )}
        />
    );

    return <Paragraph>{answer}</Paragraph>;
};

AccordionItemContent.propTypes = {
    JsxText: PropTypes.func.isRequired,
    Text: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    hasLink: PropTypes.bool.isRequired,
    hasPartner: PropTypes.bool.isRequired,
    partnerName: PropTypes.string.isRequired
};

const AccordionItemContentWithText = withText('Modals.FAQs')(AccordionItemContent);

AccordionItemContentWithText.displayName = 'AccordionItemContent';

export default AccordionItemContentWithText;
