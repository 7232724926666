export const partnerNameMap = () => ({
    asos: 'ASOS',
    capitalOne: 'Capital One',
    luma: 'Luma',
    littlewoods: 'Littlewoods',
    ocean: 'Ocean',
    postoffice: 'Post Office',
    thinkmoney: 'Thinkmoney',
    very: 'Very'
});

export const vowelStart = (brand) => ['a', 'e', 'i', 'o', 'u'].includes(brand[0]);
