import { Box } from '@cof/plastic-components';
import PropType from 'prop-types';

import { useJourneyContext } from '../../data/JourneyContext';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';

const ProductBackground = ({ children }) => {
    const { aggregatorConsumerName } = useJourneyContext();
    const { aggregatorExperiment } = useAggregatorBrandExperiment();

    const backgroundStyle = {
        CLRS: {
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/clearscore.webp),url(${process.env.PUBLIC_URL}/assets/images/clearscore-min.webp)`,
            backgroundRepeat: 'no-repeat,no-repeat',
            backgroundSize: 'cover,cover'
        },
        MSM: {
            background: 'linear-gradient(180deg, #724398 0%, #5F2A8A 28.5%, #361853 62.5%, #1C2640 100%)'
        },
        CK: {
            background: 'linear-gradient(180deg, rgba(197, 245, 207, 0.1) 0%, rgba(28, 38, 64, 0.1) 100%)'
        },
        EXPR: {
            background: 'linear-gradient(106.81deg, rgba(64, 39, 93, 1), rgba(55, 70, 118, 1), rgba(116, 58, 125, 1))'
        }
    }[aggregatorExperiment && aggregatorConsumerName];

    return (
        <Box style={backgroundStyle} data-qa-id="product-section-background">
            {children}
        </Box>
    );
};

ProductBackground.propTypes = {
    children: PropType.node.isRequired
};

export default ProductBackground;
