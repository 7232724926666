import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        heading1: COLORS.ASOS.purple350,
        heading2: COLORS.global.black,
        heading3: COLORS.ASOS.grey850,
        iconColor: COLORS.ASOS.purple350,
        linkIconColor: COLORS.ASOS.purple350,
        background: COLORS.global.grey50,
        borderedBoxColor: COLORS.global.grey50,
        errorHeading: COLORS.ASOS.grey850,
        error: COLORS.ASOS.red550,
        linkColor: COLORS.ASOS.grey850,
        linkColorDark: COLORS.global.black,
        listItem: COLORS.ASOS.grey850,
        listIcon: COLORS.ASOS.purple350,
        modalHeading: COLORS.ASOS.grey850,
        text: COLORS.ASOS.grey850,
        scrollBoxBorder: COLORS.global.grey300,
        loadingSpinner: {
            background: COLORS.global.white,
            spinner: COLORS.ASOS.grey850
        },
        label: {
            background: COLORS.global.grey50
        },
        outerContainer: {
            border: COLORS.ASOS.grey850
        },
        product: {
            background: COLORS.global.white,
            cardName: COLORS.ASOS.purple350,
            coloredDivider: COLORS.ASOS.purple350,
            titleLine1: COLORS.ASOS.purple350,
            titleLine2: COLORS.global.black,
            icons: COLORS.ASOS.purple350,
            circle: COLORS.ASOS.red600,
            bulletPoints: COLORS.ASOS.grey850,
            lockIconColor: COLORS.ASOS.green450
        },
        modal: {
            links: 'dark'
        },
        readFollowing: {
            background: COLORS.global.white
        },
        signatureBlock: {
            iconColor: COLORS.ASOS.purple350,
            background: '#F1E8FF', // Colour not approved for use elsewhere, not to be added to Plastic
            border: COLORS.global.transparent,
            checkbox: COLORS.global.white,
            textVariant: 'light',
            textColor: COLORS.global.black
        },
        section: {
            heading: {
                background: COLORS.global.grey100
            }
        },
        circle: {
            background: COLORS.global.transparent
        },
        otherThingsSection: {
            listIcon: COLORS.ASOS.purple350
        },
        checkDetails: {
            iconColor: COLORS.ASOS.purple350,
            editStageButtonVariant: 'textLink',
            stage: {
                background: COLORS.global.white,
                iconColor: COLORS.ASOS.purple350
            },
            accordion: {
                variant: 'dark',
                textColor: COLORS.global.black,
                textDecoration: 'underline',
                separatorColor: COLORS.global.white,
                backgroundColor: COLORS.global.white,
                iconColor: COLORS.ASOS.purple350
            }
        }
    }
};
