import { Box, Heading, PageRowFullHeight, Paragraph, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { withText } from '../../../../text/textStore';
import { ERROR_TYPE } from '../../../../utilities/constants';
import { useTheme } from 'styled-components';

const SubmitAgreement = ({ Text }) => {
    const theme = useTheme();
    return (
        <PageRowFullHeight data-qa-id="submit-agreement-technical-error" wrapperBg={theme.agreement.background}>
            <Heading
                data-qa-id="tech-error-heading"
                level="1"
                color={theme.agreement.errorHeading}
                textAlign={['left', null, 'center']}
            >
                <Text path="Title" />
            </Heading>
            <Box
                paddingY={['xs', 'sm', 'md']}
                maxWidth={TC.SCREEN_WIDTHS.SIX_COLUMN}
                mx="auto"
                data-qa-id="tech-error-detail-section"
            >
                <Paragraph color={theme.agreement.text} mb="xs" data-qa-id="tech-error-detail-text1">
                    <Text path="Paragraph1" />
                </Paragraph>
                <Paragraph color={theme.agreement.text} data-qa-id="tech-error-detail-text2">
                    <Text path="Paragraph2" />
                </Paragraph>
            </Box>
        </PageRowFullHeight>
    );
};

SubmitAgreement.propTypes = {
    Text: PropTypes.func.isRequired
};

const SubmitAgreementWithText = withText(`Pages.TechnicalError.${ERROR_TYPE.SUBMIT_AGREEMENT}`)(SubmitAgreement);

SubmitAgreementWithText.displayName = 'SubmitAgreement';

export default SubmitAgreementWithText;
