import { List } from '@cof/plastic-components';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { withText } from '../../text/textStore';
import { formatAddress, formatCurrency, formatNumberOfDependents } from '../../utilities/formatters';
import DataPlaybackRow from './DataPlaybackRow/DataPlaybackRow';

/**
 * DataPlaybackRow (or null where skipped) after formatting values.
 * @returns {(DataPlaybackRow|null)}
 */
const getComponent = ({ id, value: rawValue }, text, allData) => {
    const value = rawValue?.toString();

    const createPropsFns = {
        'creditRequested.balanceTransfer': () => {
            if (rawValue === 0 || rawValue === null) {
                return null;
            }
            if (!allData.isBT) {
                // edge case requested BT but not offered
                return null;
            }
            return { value1: formatCurrency(value), label: text({ path: 'BTAmount' }) };
        },
        'individual.firstName': () => ({
            value1: `${value} ${allData['individual.lastName']}`,
            value2: dayjs(allData['individual.dateOfBirth']).format('DD MMMM YYYY'),
            label: text({ path: 'BornOn' })
        }),
        'individual.annualIncome': () => ({ value1: formatCurrency(value), label: text({ path: 'Income' }) }),
        'individual.additionalQuestions.otherHouseholdIncome': () => ({
            value1: formatCurrency(value),
            label: text({ path: 'OtherIncome' })
        }),
        'individual.additionalQuestions.numberOfDependents': () => ({
            value1: formatNumberOfDependents(value),
            label: text({ path: 'Dependents' })
        }),
        'individual.cashAdvance': () => ({
            value1: text({ path: `CashAdvance.Outcome.${value}` }),
            label: text({ path: 'CashAdvance.Label' })
        }),
        'individual.currentAddress.postcode': () => ({
            value1: allData['individual.residentialStatusCode'],
            value2: formatAddress([
                allData['individual.currentAddress.houseNumber'],
                allData['individual.currentAddress.houseName'],
                allData['individual.currentAddress.flatNumber'],
                allData['individual.currentAddress.streetName'],
                allData['individual.currentAddress.locality'],
                allData['individual.currentAddress.town'],
                allData['individual.currentAddress.county'],
                allData['individual.currentAddress.postcode']
            ]),
            label: text({ path: 'At' })
        }),
        'individual.additionalQuestions.mortgagePayment': () => ({
            value1: formatCurrency(value),
            label: text({ path: 'Contribution' })
        }),
        'individual.currentAddress.yearsAtAddress': () => ({
            value1: value,
            label: text({ path: 'YearsAtAddress' })
        }),
        'individual.previousAddress.postcode': () => ({
            value2: formatAddress([
                allData['individual.previousAddress.houseNumber'],
                allData['individual.previousAddress.houseName'],
                allData['individual.previousAddress.flatNumber'],
                allData['individual.previousAddress.streetName'],
                allData['individual.previousAddress.locality'],
                allData['individual.previousAddress.town'],
                allData['individual.previousAddress.county'],
                allData['individual.previousAddress.postcode']
            ]),
            label: text({ path: 'PreviousAddress' })
        }),
        'individual.title': () => null,
        'individual.lastName': () => null,
        'individual.dateOfBirth': () => null,
        'individual.currentAddress.houseNumber': () => null,
        'individual.currentAddress.houseName': () => null,
        'individual.currentAddress.flatNumber': () => null,
        'individual.currentAddress.streetName': () => null,
        'individual.currentAddress.locality': () => null,
        'individual.currentAddress.town': () => null,
        'individual.currentAddress.county': () => null,
        'individual.currentAddress.monthsAtAddress': () => null,
        'individual.residentialStatusCode': () => null,
        'individual.previousAddress.houseNumber': () => null,
        'individual.previousAddress.houseName': () => null,
        'individual.previousAddress.flatNumber': () => null,
        'individual.previousAddress.streetName': () => null,
        'individual.previousAddress.locality': () => null,
        'individual.previousAddress.town': () => null,
        'individual.previousAddress.county': () => null,
        'individual.previousAddress.yearsAtAddress': () => null,
        'individual.previousAddress.monthsAtAddress': () => null,
        'default': () => ({ value1: value })
    };

    const props = (createPropsFns[id] || createPropsFns.default)();
    return props ? <DataPlaybackRow id={id} key={id} {...props} /> : null;
};

/**
 * List component containing DataPlaybackRow items
 * @returns {List}
 */
const DataPlayback = ({ stageData, allData, Text: text }) => (
    <List pl={[0, 0]}>{stageData.map((item) => getComponent(item, text, allData))}</List>
);

DataPlayback.propTypes = {
    stageData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
    ).isRequired,
    allData: PropTypes.object.isRequired,
    Text: PropTypes.func.isRequired
};

const DataPlaybackWithText = withText('Sections.CheckDetails.DataPlayback')(DataPlayback);

DataPlaybackWithText.displayName = 'DataPlayback';

export default DataPlaybackWithText;
