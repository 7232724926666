import { useEffect } from 'react';
import config from '@cof/ukweb-config/lib/clientConfig';
import { isBTProduct, isBTRequested } from '../../utilities/product';
import { addNewRelicPageAction } from '../../utilities/newRelic';
import { useZuko } from '../../utilities/useZuko';
import { useJourneyContext } from '../../data/JourneyContext';
import setQueryParam from '../../utilities/setQueryParams';

const useEditQueryParams = (editRequestResponse, editRequestInput) => {
    const {
        agreementData,
        consumerName,
        productId,
        vendorCode,
        sParameter,
        product,
        channel,
        campaign,
        pcr,
        aggregatorConsumerName,
        brand
    } = useJourneyContext();

    const isGuarantee = agreementData.btDecision === 'Y';
    const { definitionId, experimentVariant } = agreementData;

    const { trackEditEvent } = useZuko({
        formDefinitionId: definitionId,
        productId,
        vendorCode,
        channel,
        brand: aggregatorConsumerName !== null ? consumerName : brand,
        balanceTransfer: isBTProduct(agreementData),
        btGuarantee: isGuarantee,
        experimentVariant: experimentVariant ?? undefined
    });

    useEffect(() => {
        if (!editRequestResponse) return;

        // emit `Clicked edit details` to zuko
        trackEditEvent();
        // emit `Clicked edit details` to new relic
        addNewRelicPageAction('Clicked edit details');

        const {
            QuickCheck: { capitalOne: capitalOneQC, partner: partnerQC }
        } = config.get('externalLinks');

        const { quotationToken } = editRequestResponse.data;
        const isBTOffered = isBTProduct(agreementData);
        const isBTReq = isBTRequested(agreementData);
        const editUrl = brand === 'capitalOne' ? capitalOneQC : partnerQC.replace('{partner}', brand);

        const qs = new URLSearchParams();
        setQueryParam(qs, 'quotation', quotationToken);
        setQueryParam(qs, 'p', product?.toUpperCase());
        setQueryParam(qs, 's', sParameter);
        setQueryParam(qs, 'pcr', pcr);
        setQueryParam(qs, 'v', vendorCode);
        if (isBTReq && !isBTOffered) {
            setQueryParam(qs, 'btnotoffered', isBTReq && !isBTOffered);
        }
        setQueryParam(qs, 'campaign', campaign);
        setQueryParam(qs, 'channel', channel);

        window.location.assign(`${editUrl}/${editRequestInput.stageId}?${qs.toString()}`);
    }, [
        agreementData,
        brand,
        campaign,
        channel,
        editRequestInput,
        editRequestResponse,
        pcr,
        product,
        sParameter,
        trackEditEvent,
        vendorCode
    ]);
};

export default useEditQueryParams;
