import { Heading as PlasticHeading, Text, THEME_CONSTANTS } from '@cof/plastic-components';
import { func } from 'prop-types';

import { useTheme } from 'styled-components';

import { useJourneyContext } from '../../data/JourneyContext';
import findFormValue from '../../utilities/findFormValue';
import SecondaryHeading from '../SecondaryHeading/SecondaryHeading';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';
import { withText } from '../../text/textStore';

const { COLORS } = THEME_CONSTANTS;

const Heading = ({ JsxText }) => {
    const theme = useTheme();
    const { agreementData } = useJourneyContext();
    const { aggregatorExperiment, lightMode } = useAggregatorBrandExperiment();

    let titleColor1 = theme.agreement.product.titleLine1;
    let titleColor2 = theme.agreement.product.titleLine2;

    if (aggregatorExperiment) {
        if (lightMode) {
            titleColor1 = titleColor2 = COLORS.global.white;
        } else {
            titleColor1 = titleColor2 = COLORS.capitalOne.blue750;
        }
    }

    return (
        <PlasticHeading level="1" pb="0">
            <Text as="div" color={titleColor1} size={null} fontWeight="inherit">
                <JsxText
                    path="HeadingLine1Dynamic"
                    params={{
                        CUSTOMER_NAME: (
                            <span>{agreementData && findFormValue('individual.firstName', agreementData)}</span>
                        )
                    }}
                />
            </Text>
            <Text as="div" data-qa-id="heading-2" color={titleColor2} size={null}>
                <SecondaryHeading />
            </Text>
        </PlasticHeading>
    );
};

Heading.propTypes = {
    JsxText: func.isRequired
};

const HeadingWithText = withText('Pages.Landing')(Heading);

export default HeadingWithText;
