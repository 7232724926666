import { Box, Flex, Paragraph, useBrand, THEME_CONSTANTS as TC, BRANDS } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import styled, { useTheme } from 'styled-components';

import { withText } from '../../../text/textStore';
import { useAggregatorBrandExperiment } from '../../../hooks/useExperiment/useAggregatorBrandExperiment';
import { useJourneyContext } from '../../../data/JourneyContext';

const Reason = ({ Text, Icon, number, aggregatorAccentColor }) => {
    const theme = useTheme();
    const brand = useBrand();
    const { aggregatorConsumerName } = useJourneyContext();
    const { lightMode } = useAggregatorBrandExperiment();

    let stopColor1;
    let stopColor2;

    let textColor = theme.agreement.text;

    if (lightMode) {
        textColor = TC.COLORS.capitalOne.blue750;
        stopColor1 = stopColor2 = aggregatorAccentColor[aggregatorConsumerName];
    } else {
        if (brand === BRANDS.CAPITAL_ONE) {
            stopColor1 = TC.COLORS.capitalOne.teal450;
            stopColor2 = TC.COLORS.capitalOne.yellow250;
        } else {
            stopColor1 = stopColor2 = theme.agreement.iconColor;
        }
    }

    const StyledIcon = styled(Icon)`
        --color-stop-1: ${stopColor1};
        --color-stop-2: ${stopColor2};
    `;

    return (
        <Flex
            flexDirection={['row', 'column']}
            justifyContent="center"
            alignItems="center"
            px={[0, 'xs']}
            data-qa-id={`reason-to-believe-${number}`}
        >
            <StyledIcon data-qa-id={`reason-to-believe-${number}-icon`} />
            <Box width={[0.8, 1]} ml={['sm', 0]} my="xs">
                <Paragraph
                    color={textColor}
                    fontWeight={600}
                    pb="2px"
                    data-qa-id={`reason-to-believe-${number}-line-1`}
                >
                    <Text path={`${number}.Heading`} />
                </Paragraph>
                <Paragraph color={textColor} pb={[0, 'xs']} data-qa-id={`reason-to-believe-${number}-line-2`}>
                    <Text path={`${number}.Body`} />
                </Paragraph>
            </Box>
        </Flex>
    );
};

Reason.propTypes = {
    Text: PropTypes.func.isRequired,
    Icon: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    aggregatorAccentColor: PropTypes.object.isRequired
};

const ReasonWithText = withText('Sections.Product.ReasonsToBelieve')(Reason);
ReasonWithText.displayName = 'Reason To Believe';

export default ReasonWithText;
