// eslint-disable-next-line import/prefer-default-export
export const getLineGuranteeCreditLimit = (product) => {
    const maxCreditLimit = Number.parseInt(product.offered?.maxCreditLimit?.replace(/,/g, ''), 10);

    const lineGuaranteeCreditLimit = Math.min(
        Number.parseInt(product.offered?.lineGuaranteeMinCreditLimit?.replace(/,/g, ''), 10),
        maxCreditLimit
    );
    return { lineGuaranteeCreditLimit, maxCreditLimit };
};
