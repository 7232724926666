import { Accordion, AccordionItem, Heading, Link, Paragraph, useBrand } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import styled, { css, useTheme } from 'styled-components';

import Modal from '../../components/Modal';
import CloseButton from '../../components/Modal/CloseButton';
import ModalLink from '../../components/ModalLink';
import { withText } from '../../text/textStore';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer';
import { partnerNameMap, vowelStart } from '../../utilities/partner';
import AccordionItemContent from './AccordionItemContent';

const getAccordionItems = (text, brand) => {
    const items = [];

    for (let index = 0; index < 12; index += 1) {
        const path = `Questions.${index}`;
        let questionText = text({ path: `${path}.Heading` });
        const answerText = text({ path: `${path}.Answer` });
        const hasLink = answerText.includes('{{LINK}}');
        const hasPartner = answerText.includes('{{PARTNER}}');
        const partnerName = partnerNameMap()[brand];
        questionText = questionText.replace(
            / a {{PARTNER}}/g,
            vowelStart(brand) ? ` an ${partnerName}` : ` a ${partnerName}`
        );
        questionText = questionText.replace(/{{PARTNER}}/g, partnerName);

        items.push(
            <AccordionItem key={`accordion-item-${index}`} heading={questionText}>
                <AccordionItemContent path={path} hasLink={hasLink} hasPartner={hasPartner} partnerName={partnerName} />
            </AccordionItem>
        );
    }

    return items;
};

const onFAQModalOpen = (buttonText, onOpen) => {
    pushToDataLayer({
        event: EVENTS.MODAL_OPEN,
        modalName: buttonText
    });
    onOpen();
};

const StyledHeading = styled(Heading)`
    ${({ theme }) => css`
        color: ${theme.agreement.modalHeading};
    `};
`;

const FAQs = ({ Text, JsxText }) => {
    const brand = useBrand();
    const theme = useTheme();
    return (
        <Modal
            modalContent={({ onClose, focusableElementRef }) => (
                <div>
                    <CloseButton onClick={onClose} ref={focusableElementRef} />
                    <StyledHeading data-qa-id="faq-modal-title" level="3" textAlign="left" mb={['xs', 'sm', 'md']}>
                        <Text path="Heading" />
                    </StyledHeading>
                    <Accordion items={getAccordionItems(Text, brand)} data-qa-id="faq-accordion" mb="md" />
                    <div data-qa-id="faq-modal-addendum">
                        <Paragraph>
                            <JsxText
                                path="AddendumLine1"
                                params={{
                                    LINK: (
                                        <Link
                                            href={`tel:${Text({ path: 'PhoneNumber' })}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            data-qa-id="faq-modal-phone-number"
                                        >
                                            <Text path="PhoneNumber" />
                                        </Link>
                                    )
                                }}
                            />
                        </Paragraph>
                        <Paragraph>
                            <Text path="AddendumLine2" />
                        </Paragraph>
                    </div>
                </div>
            )}
            modalTrigger={({ onOpen, focusableElementRef }) => (
                <ModalLink
                    qaId="faq-modal-button"
                    variant="dark"
                    color={theme.agreement.linkColorDark}
                    onClick={() => onFAQModalOpen(Text({ path: 'TriggerText' }), onOpen)}
                    ref={focusableElementRef}
                    iconColor={theme.agreement.linkIconColor}
                >
                    <Text path="TriggerText" />
                </ModalLink>
            )}
            ariaLabel={Text({ path: 'Heading' })}
        />
    );
};
FAQs.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const FAQsWithText = withText('Modals.FAQs')(FAQs);

FAQsWithText.displayName = 'FAQs';

export default FAQsWithText;
