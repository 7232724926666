import { css } from 'styled-components';

const submitButton = ({ theme }) => css`
    background-color: ${theme.colors.ASOS.green650};
    border: ${theme.colors.ASOS.green650};

    &:hover {
        background-color: #006d3a;
        border: #006d3a;
    }
`;

export default submitButton;
