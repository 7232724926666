import { Box, HelperText, Input, Label } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { withText } from '../../../text/textStore';

const EmailAddress = ({ Text }) => {
    const {
        register,
        formState: { errors }
    } = useFormContext();

    return (
        <Box data-qa-id="missing-details-email-address-box">
            <Label data-qa-id="missing-details-email-address-label">
                <Text path="Fields.EmailAddress" />
                <Input
                    {...register('MISSING_DETAILS.emailAddress', {
                        required: Text({ path: 'Errors.Email.Required' }),
                        pattern: {
                            value: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,63}|[0-9]{1,3})(]?)$/,
                            message: Text({ path: 'Errors.Email.Invalid' })
                        }
                    })}
                    variant={errors.MISSING_DETAILS?.emailAddress?.message ? 'error' : undefined}
                    style={{ width: '343px' }}
                    data-qa-id="missing-details-email-address-input"
                />
            </Label>
            <Box>
                {errors.MISSING_DETAILS?.emailAddress?.message && (
                    <HelperText variant="error" data-qa-id="missing-details-email-error-message">
                        {errors.MISSING_DETAILS?.emailAddress?.message}
                    </HelperText>
                )}
            </Box>
        </Box>
    );
};

EmailAddress.propTypes = {
    Text: PropTypes.func.isRequired
};

const EmailAddressWithText = withText('Sections.MissingDetails')(EmailAddress);

EmailAddressWithText.displayName = 'EmailAddress';

export default EmailAddressWithText;
