import { Box, HelperText, Input, Label } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { withText } from '../../../text/textStore';

const Occupation = ({ Text }) => {
    const {
        register,
        formState: { errors }
    } = useFormContext();

    return (
        <Box data-qa-id="missing-details-occupation-box">
            <Label data-qa-id="missing-details-occupation-label">
                <Text path="Fields.Occupation" />
                <Input
                    {...register('MISSING_DETAILS.occupation', {
                        maxLength: {
                            value: 32,
                            message: Text({ path: 'Errors.Occupation.Length' })
                        },
                        pattern: {
                            value: /^[0-9a-zA-Z'`,.& -]+$/,
                            message: Text({ path: 'Errors.Occupation.Invalid' })
                        }
                    })}
                    variant={errors.MISSING_DETAILS?.occupation?.message ? 'error' : undefined}
                    style={{ width: '343px' }}
                    data-qa-id="missing-details-occupation-input"
                />
            </Label>
            <Box>
                {errors.MISSING_DETAILS?.occupation?.message && (
                    <HelperText variant="error" data-qa-id="missing-details-occupation-error-message">
                        {errors.MISSING_DETAILS?.occupation?.message}
                    </HelperText>
                )}
            </Box>
        </Box>
    );
};

Occupation.propTypes = {
    Text: PropTypes.func.isRequired
};

const OccupationWithText = withText('Sections.MissingDetails')(Occupation);

OccupationWithText.displayName = 'Occupation';

export default OccupationWithText;
