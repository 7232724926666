import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    colors: {
        styledParagraph: COLORS.ocean.blue800,
        styledText: COLORS.ocean.blue800,
        styledTextLabel: COLORS.ocean.blue800
    }
};
