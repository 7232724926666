import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

import { withText } from '../../../../../../text/textStore';
import { isGreaterThanEqualToZero } from '../../../../../../utilities/product';
import { getVendorCodeFromUrl } from '../../../../../../utils';
import BaseFeature, { featureComponentPropTypes } from './BaseFeature';
import { useBoldedFeature } from './utils';
import { useJourneyContext } from '../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) => isGreaterThanEqualToZero(agreementData.product.representative.APR);

const StyledBr = styled.br`
    ${({ theme }) => css`
        display: none;

        ${theme.breakpoints.mq.desktopMin} {
            display: block;
        }
    `};
`;

const AprFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const vendorCode = getVendorCodeFromUrl() || agreementData?.vendorCode;
    const isBoldedFeature = useBoldedFeature({ consumerName: agreementData.consumerName, vendorCode });
    return (
        <BaseFeature>
            <JsxText
                path='Features.Feature2'
                params={{
                    APR: (
                        <span style={{ fontWeight: isBoldedFeature ? 600 : 'inherit' }}>
                            <NumberFormat
                                value={agreementData.product.representative.APR}
                                displayType="text"
                                decimalScale={1}
                                suffix="% APR"
                            />
                            <StyledBr />
                        </span>
                    )
                }}
            />
        </BaseFeature>
    );
};

AprFeature.propTypes = featureComponentPropTypes;

const AprFeatureWithText = withText('Sections.Product')(AprFeature);

export default AprFeatureWithText;
