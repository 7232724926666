import { ListItem, Text as PlasticText } from '@cof/plastic-components';
import PropType from 'prop-types';

import { useTheme } from 'styled-components';

const DataPlaybackRow = ({ id, value1, value2, label }) => {
    const theme = useTheme();
    return (
        <ListItem iconColor={theme.agreement.listIcon}>
            <PlasticText data-qa-id={id} color={theme.agreement.checkDetails.stage.textColor}>
                <span>{value1}</span>
                <> </>
                <span>{label}</span>
                <> </>
                <span>{value2}</span>
            </PlasticText>
        </ListItem>
    );
};

DataPlaybackRow.propTypes = {
    id: PropType.string,
    value1: PropType.string,
    value2: PropType.string,
    label: PropType.string
};

export default DataPlaybackRow;
