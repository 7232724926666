import { useBrand } from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';
import PropType from 'prop-types';

import getCardApplyUrl from './getCardApplyUrl';

const HiddenFormComponent = ({ submissionForm, raidAccessToken, iovationBlackBox }) => {
    const brand = useBrand();
    const { CardApply } = config.get('externalLinks') || {};
    const { downstreamVersionIsEnabled } = config.get('useDownstreamVersion') || {};
    let cardApplyUrl = '';

    if (downstreamVersionIsEnabled) {
        cardApplyUrl = getCardApplyUrl(CardApply, brand);
    } else {
        cardApplyUrl = CardApply[brand];
    }

    return (
        <form data-qa-id="hiddenForm" ref={submissionForm} action={cardApplyUrl} method="post">
            <input ref={raidAccessToken} type="hidden" name="capitalone:application:raidAccessToken" />
            <input ref={iovationBlackBox} type="hidden" name="capitalone:application:iovationBlackBox" />
        </form>
    );
};

HiddenFormComponent.propTypes = {
    submissionForm: PropType.oneOfType([PropType.func, PropType.shape({ current: PropType.any })]),
    raidAccessToken: PropType.oneOfType([PropType.func, PropType.shape({ current: PropType.any })]),
    iovationBlackBox: PropType.oneOfType([PropType.func, PropType.shape({ current: PropType.any })])
};

export default HiddenFormComponent;
