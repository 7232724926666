import { css } from 'styled-components';

const marketingPreferencesText = ({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
`;

const howWeUseYourInfoText = ({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
`;

export default {
    marketingPreferencesText,
    howWeUseYourInfoText
};
