import { THEME_CONSTANTS, postOfficeNextTheme } from '@cof/plastic-components';

export default {
    ...postOfficeNextTheme.components.header,
    colors: {
        background: {
            mobile: THEME_CONSTANTS.COLORS.global.grey50,
            desktop: THEME_CONSTANTS.COLORS.global.grey50
        },
        border: {
            mobile: THEME_CONSTANTS.COLORS.global.white,
            desktop: THEME_CONSTANTS.COLORS.global.white
        }
    }
};
