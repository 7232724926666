import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        background: COLORS.global.grey50,
        heading1: COLORS.postOffice.red549,
        borderedBoxColor: COLORS.global.grey100,
        errorHeading: COLORS.next.postOffice.red550,
        error: COLORS.next.postOffice.red550,
        heading2: COLORS.next.postOffice.purple750,
        heading3: COLORS.next.postOffice.red550,
        iconColor: COLORS.next.postOffice.red550,
        linkColor: COLORS.next.postOffice.purple750,
        listItem: COLORS.next.postOffice.red550,
        text: COLORS.next.postOffice.purple750,
        scrollBoxBorder: COLORS.global.grey300,
        section: {
            heading: {
                background: COLORS.global.grey100
            }
        },
        circle: {
            background: COLORS.next.postOffice.pink100
        },
        checkDetails: {
            editStageButtonVariant: 'textLink',
            stage: {
                background: COLORS.global.white
            },
            accordion: {
                variant: 'dark',
                separatorColor: COLORS.global.white
            }
        },
        footer: {
            background: 'lightSecondary'
        },
        loadingSpinner: {
            background: COLORS.global.white,
            spinner: COLORS.next.postOffice.red550
        },
        label: {
            background: COLORS.global.white
        },
        outerContainer: {
            border: COLORS.next.postOffice.red550
        },
        product: {
            background: COLORS.global.white,
            cardName: COLORS.next.postOffice.red550,
            coloredDivider: COLORS.next.postOffice.red550,
            titleLine1: COLORS.next.postOffice.red550,
            titleLine2: COLORS.postOffice.purple750,
            circle: COLORS.next.postOffice.pink100,
            bulletPoints: COLORS.next.postOffice.purple750,
            icons: COLORS.next.postOffice.red550,
            lockIconColor: COLORS.next.postOffice.blue200
        },
        modal: {
            links: 'dark'
        },
        readFollowing: {
            background: COLORS.global.white
        },
        signatureBlock: {
            iconColor: COLORS.postOffice.red549,
            background: '#FCE0E9', // Colour not approved for use elsewhere, not to be added to Plastic
            border: COLORS.global.transparent,
            checkbox: COLORS.global.black,
            textVariant: 'light'
        }
    }
};
