import { css } from 'styled-components';

import disclosuresHtml from '../../capitalOne/componentStyles/disclosuresHtml';

export default ({ theme }) => css`
    ${disclosuresHtml},
    h3 {
        ${theme.components.heading.small};
        color: ${theme.agreement.heading2};
    }
    section {
        border-color: ${theme.agreement.scrollBoxBorder};
    }
`;
