import { CheckCircledIcon, ListItem, Text as PlasticText, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import PropType from 'prop-types';

import { useTheme } from 'styled-components';

import { useAggregatorBrandExperiment } from '../../../../../../hooks/useExperiment/useAggregatorBrandExperiment';

const BaseFeature = ({ children, listItemProps = {}, qaId }) => {
    const theme = useTheme();
    const { lightMode } = useAggregatorBrandExperiment();

    return (
        <ListItem
            data-qa-id={qaId}
            Icon={CheckCircledIcon}
            iconColor={lightMode ? TC.COLORS.capitalOne.blue750 : theme.agreement.product.icons}
            {...listItemProps}
        >
            <PlasticText
                fontSize={['1.6rem', null, '2.4rem']}
                color={lightMode ? TC.COLORS.capitalOne.blue750 : theme.agreement.product.bulletPoints}
            >
                {children}
            </PlasticText>
        </ListItem>
    );
};

BaseFeature.propTypes = {
    children: PropType.node.isRequired,
    listItemProps: PropType.object,
    qaId: PropType.string
};

export const featureComponentPropTypes = {
    JsxText: PropType.func.isRequired
};

export default BaseFeature;
