import { PageRow } from '@cof/plastic-components';
import styled, { css } from 'styled-components';

const CustomPageRow = styled(PageRow)`
    ${({ theme, indent = true }) => css`
        ${indent
            ? `
            padding: ${theme.space.md} ${theme.space.sm};
            ${theme.breakpoints.mq.tabletMin} {
                padding: ${theme.space.lg} ${theme.space.lg};
            }
        `
            : `padding: 0`}
    `}
`;

CustomPageRow.displayName = 'CustomPageRow';

export default CustomPageRow;
