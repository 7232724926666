import Cookies from 'js-cookie';

const validateDownstreamVersion = (downstreamVersion) => {
    const validVersions = {
        SIT2: 'SIT2',
        SIT3: 'SIT3'
    };

    const isValid = validVersions[downstreamVersion];
    return isValid;
};

const getCardApplyUrl = (cardApply, brand) => {
    // get downstreamVersion value from cookie
    const downstreamVersion = Cookies.get('downstreamVersion');

    // validate cookie value
    if (validateDownstreamVersion(downstreamVersion)) {
        return cardApply[downstreamVersion][brand];
    }

    // return SIT2 by default
    return cardApply.SIT2[brand];
};

export default getCardApplyUrl;
