import { THEME_CONSTANTS as TC, littlewoodsTheme } from '@cof/plastic-components';

export default {
    ...littlewoodsTheme.components.header,
    colors: {
        background: {
            mobile: TC.COLORS.littlewoods.grey50,
            desktop: TC.COLORS.littlewoods.grey50
        },
        border: {
            mobile: TC.COLORS.littlewoods.grey50,
            desktop: TC.COLORS.littlewoods.grey50
        }
    }
};
