import { Box } from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import styled, { css } from 'styled-components';

const ScrollBox = styled(Box)`
    overflow-y: scroll;
    background:
        linear-gradient(#fff 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-size:
        100% 4rem,
        100% 4rem,
        100% 1.5rem,
        100% 1.5rem;
    background-attachment: local, local, scroll, scroll;

    ${cssSystem({
        'max-height': ['30rem', null, '50rem']
    })}

    ${({ theme }) => css`
        border: 0.1rem solid ${theme.agreement.scrollBoxBorder};
    `};
`;

ScrollBox.displayName = 'ScrollBox';

export default ScrollBox;
