import { useCallback, useState } from 'react';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer/dataLayer';
import transformPreferences from '../../utilities/transformPreferences';
import { useJourneyContext } from '../../data/JourneyContext';
import config from '@cof/ukweb-config/lib/clientConfig';
import { submitAgreement } from '../../data';
import { validatePANS } from '../../utils';
import { Buffer } from 'buffer';

const useSubmitForm = ({ setSpinnerDisplayed }) => {
    const [submittingData, setSubmittingData] = useState(false);
    const [submitAgreementResponse, setSubmitAgreementResponse] = useState(false);
    const [invalidPANs, setInvalidPANs] = useState([]);
    const [hasError, setHasError] = useState();
    const { isEnabled: iovationEnabled } = config.get('iovation') || {};
    const { agreementData, sessionId, productId, vendorCode, channel } = useJourneyContext();
    const { definitionId, panPublicKey, missingUserDetails } = agreementData;
    const submitForm = useCallback(
        // eslint-disable-next-line complexity
        async (formInputs) => {
            setSubmittingData(true);
            const iovationBlackBox = iovationEnabled
                ? Buffer.from(window.IGLOO.getBlackbox().blackbox).toString('base64')
                : null;

            pushToDataLayer({
                event: EVENTS.AGREEMENT_SUBMISSION,
                formDefinitionId: definitionId,
                productId,
                vendorCode,
                channel
            });

            // get valid PANS
            let validatedPANS;
            try {
                validatedPANS = await validatePANS({ pans: formInputs?.BT, panPublicKey, agreementData });
            } catch (error) {
                setHasError(true);
                return;
            }
            setSpinnerDisplayed(true);
            const { unknownError, invalidBINsError, response } = await submitAgreement({
                agreementToken: agreementData.agreementToken,
                preferences: transformPreferences(formInputs),
                zukoVisitorId: window.Zuko?.getVisitorId(),
                sessionId,
                ePANs: validatedPANS,
                missingDetails: missingUserDetails && formInputs.MISSING_DETAILS,
                iovationBlackBox
            });

            if (unknownError) {
                setHasError(true);
                setSpinnerDisplayed(false);
            } else if (invalidBINsError) {
                setSpinnerDisplayed(false);
                const pans = [];
                if (Array.isArray(response?.data?.validBINs)) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const [i, isValid] of Object.entries(response.data.validBINs)) {
                        const pan = formInputs?.BT[i]?.cardNumber;
                        if (!isValid && typeof pan === 'string') {
                            pans.push(pan);
                        }
                    }
                }
                setInvalidPANs(pans);
            }
            setSubmitAgreementResponse(response);
            setSubmittingData(false);
        },
        [
            agreementData,
            channel,
            definitionId,
            iovationEnabled,
            missingUserDetails,
            panPublicKey,
            productId,
            sessionId,
            vendorCode,
            setSpinnerDisplayed
        ]
    );

    return { submitForm, invalidPANs, submittingData, hasError, submitAgreementResponse };
};

export default useSubmitForm;
