import NumberFormat from 'react-number-format';

import { withText } from '../../../../../../../text/textStore';
import { isBTProduct, isGreaterThanEqualToZero } from '../../../../../../../utilities/product';
import BaseFeature, { featureComponentPropTypes } from '../BaseFeature';
import { useJourneyContext } from '../../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) => {
    const btTeaser = agreementData.product.interest.compound?.balanceTransferIntroductory;
    return isBTProduct(agreementData) && isGreaterThanEqualToZero(btTeaser);
};

const BtFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const btTeaser = agreementData.product.interest.compound?.balanceTransferIntroductory;
    const btMonths = agreementData.product.interest.teaserPeriods?.balanceTransferTeaserMonths;
    return (
        <BaseFeature qaId="bt-introductory-rate">
            <JsxText
                path='Features.Feature3'
                params={{
                    INTRODUCTORY_RATE: <NumberFormat value={btTeaser} displayType="text" decimalScale={0} suffix="%" />,
                    TRANSFER_MONTHS: <span>{btMonths}</span>
                }}
            />
        </BaseFeature>
    );
};

BtFeature.propTypes = featureComponentPropTypes;

const BtFeatureWithText = withText('Sections.Product')(BtFeature);

export default BtFeatureWithText;
