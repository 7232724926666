import { CapitalOneLogoPrimaryDark, Flex, Box, CapitalOneLogoPrimaryLight } from '@cof/plastic-components';
import PropType from 'prop-types';

import Logo from '../Logo';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';
import AggregatorLogo from './AggregatorLogo';

const LogoComponent = ({ showAggregatorLogo = false, appForm = false }) => {
    const { aggregatorExperiment, lightMode } = useAggregatorBrandExperiment();
    let style;
    if (!aggregatorExperiment || !appForm) {
        style = { borderRight: '1px', paddingRight: '10px', borderColor: '#000', borderStyle: 'solid' };
    }
    return (
        <Flex
            width="100%"
            height="100%"
            justifyContent={['center', null, 'flex-start']}
            alignItems="center"
            data-qa-id="logo-container"
        >
            {showAggregatorLogo ? (
                <Box style={style}>{lightMode ? <CapitalOneLogoPrimaryLight /> : <CapitalOneLogoPrimaryDark />}</Box>
            ) : (
                <Logo />
            )}
            <AggregatorLogo appForm={appForm} />
        </Flex>
    );
};

LogoComponent.propTypes = {
    showAggregatorLogo: PropType.bool,
    appForm: PropType.bool
};

export default LogoComponent;
