import { PREFERENCE } from '../utilities/constants';

const transformPreferences = (formInputs) => {
    return {
        [PREFERENCE.ONLINE_STATEMENTS]: formInputs[PREFERENCE.ONLINE_STATEMENTS],
        [PREFERENCE.MARKETING_CONTACT_PERMISSION]: formInputs[PREFERENCE.MARKETING_CONTACT_PERMISSION]
    };
};

export default transformPreferences;
