import { Box, HelperText, Label, Text as PlasticText, AccordionItem, CurrencyInput } from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { useFormContext, Controller } from 'react-hook-form';
import JsxParser from 'react-jsx-parser';

import { withText } from '../../../text/textStore';

const OtherIncome = ({ Text }) => {
    const {
        formState: { errors },
        control
    } = useFormContext();

    return (
        <Box data-qa-id="missing-details-other-income-box">
            <Box marginBottom="sm">
                <Label data-qa-id="missing-details-other-income-label">
                    <Text path="Fields.OtherHouseholdIncome.Title" />
                    <HelperText data-qa-id="missing-details-other-income-helpertext">
                        <Text path="Fields.OtherHouseholdIncome.Subtitle" />
                    </HelperText>
                    <Controller
                        control={control}
                        name="MISSING_DETAILS.otherHouseholdIncome"
                        rules={{
                            maxLength: {
                                value: 7,
                                message: Text({ path: 'Errors.OtherIncome.Maximum' })
                            },
                            pattern: {
                                value: /^(,*?\d,*?){1,7}$/,
                                message: Text({ path: 'Errors.OtherIncome.Invalid' })
                            }
                        }}
                        render={({ field: { onChange, onBlur, name } }) => (
                            <CurrencyInput
                                data-qa-id="missing-details-other-income-input"
                                style={{ width: '343px' }}
                                name={name}
                                onValueChange={(InputValue) => onChange(InputValue)}
                                onBlur={onBlur}
                                variant={errors.MISSING_DETAILS?.otherHouseholdIncome?.message ? 'error' : undefined}
                                allowDecimals={false}
                            />
                        )}
                    />
                </Label>
                {errors.MISSING_DETAILS?.otherHouseholdIncome?.message && (
                    <HelperText variant="error" data-qa-id="missing-details-other-income-error-message">
                        {errors.MISSING_DETAILS?.otherHouseholdIncome?.message}
                    </HelperText>
                )}
            </Box>
            <AccordionItem
                data-qa-id="missing-details-other-income-accordian-trigger"
                heading={<Text path="Fields.OtherHouseholdIncome.Accordian.Trigger" />}
            >
                <PlasticText as="span" size="small" whiteSpace="pre-wrap" marginTop="xs" paddingBottom="sm">
                    <JsxParser jsx={Text({ path: 'Fields.OtherHouseholdIncome.Accordian.Content' })} />
                </PlasticText>
            </AccordionItem>
        </Box>
    );
};

OtherIncome.propTypes = {
    Text: PropTypes.func.isRequired
};

const OtherIncomeWithText = withText('Sections.MissingDetails')(OtherIncome);

OtherIncomeWithText.displayName = 'OtherIncome';

export default OtherIncomeWithText;
