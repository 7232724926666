import {
    Box,
    HelperText,
    Input,
    Label,
    Text as PlasticText,
    InfoPanel,
    PhoneIcon,
    Button
} from '@cof/plastic-components';
import PropTypes, { array, func, object, string } from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { withText } from '../../../text/textStore';

const HomePhoneNumber = ({ Text, Text: text }) => {
    const [showHomePhoneNumber, setShowHomePhoneNumber] = useState(false);

    const {
        register,
        formState: { errors },
        getValues
    } = useFormContext();

    return (
        <>
            {!showHomePhoneNumber && (
                <Button
                    data-qa-id="missing-details-home-phone-button"
                    variant="secondary"
                    mt="lg"
                    mb="xs"
                    mx="auto"
                    onClick={() => setShowHomePhoneNumber(true)}
                >
                    <Text path="Fields.PhoneNumber.Button" />
                </Button>
            )}
            {showHomePhoneNumber && (
                <Box>
                    <Label data-qa-id="missing-details-home-phone-number-label">
                        <Text path="Fields.PhoneNumber.Home" />
                        <Input
                            {...register('MISSING_DETAILS.homePhoneNumber', {
                                validate: {
                                    req: (value) =>
                                        !!(value || getValues('MISSING_DETAILS.mobilePhoneNumber')) ||
                                        text({ path: 'Errors.Phone.Required' }),
                                    minLength: (value) =>
                                        !!(value.length > 9 || !value) || text({ path: 'Errors.Phone.Home.MinLength' }),
                                    maxLength: (value) =>
                                        !!(value.length < 12 || !value) ||
                                        text({ path: 'Errors.Phone.Home.MaxLength' }),
                                    valid: (value) =>
                                        !!(value.match(/^0[1,2]\d{8,9}$/) || !value) ||
                                        text({ path: 'Errors.Phone.Home.Valid' })
                                },
                                deps: ['MISSING_DETAILS.mobilePhoneNumber', 'MISSING_DETAILS.homePhoneNumber']
                            })}
                            variant={errors.MISSING_DETAILS?.homePhoneNumber?.message ? 'error' : undefined}
                            style={{ width: '343px', marginBottom: '0.8rem' }}
                            type="number"
                            data-qa-id="missing-details-home-phone-input"
                        />
                    </Label>
                    {errors.MISSING_DETAILS?.homePhoneNumber?.message && (
                        <HelperText variant="error" data-qa-id="missing-details-home-phone-error-message">
                            {errors.MISSING_DETAILS?.homePhoneNumber?.message}
                        </HelperText>
                    )}
                </Box>
            )}
        </>
    );
};

HomePhoneNumber.propTypes = {
    Text: PropTypes.func.isRequired
};

const HomePhoneNumberWithText = withText('Sections.MissingDetails')(HomePhoneNumber);

const PhoneNumbers = ({ Text }) => {
    const [isMobileFocused, setMobileFocus] = useState(false);

    const {
        register,
        formState: { errors },
        getValues
    } = useFormContext();

    return (
        <Box data-qa-id="missing-details-phone-numbers-box">
            <Label data-qa-id="missing-details-mobile-phone-label">
                <Text path="Fields.PhoneNumber.Mobile" />
                <Input
                    {...register('MISSING_DETAILS.mobilePhoneNumber', {
                        validate: {
                            req: (value) =>
                                !!(value || getValues('MISSING_DETAILS.homePhoneNumber')) ||
                                Text({ path: 'Errors.Phone.Required' }),
                            length: (value) =>
                                !!(value.length === 11 || !value) || Text({ path: 'Errors.Phone.Mobile.Length' }),
                            valid: (value) =>
                                !!(value.match(/^07\d{9}$/) || !value) || Text({ path: 'Errors.Phone.Mobile.Valid' })
                        },
                        deps: ['MISSING_DETAILS.mobilePhoneNumber', 'MISSING_DETAILS.homePhoneNumber']
                    })}
                    variant={errors.MISSING_DETAILS?.mobilePhoneNumber?.message ? 'error' : undefined}
                    type="number"
                    data-qa-id="missing-details-mobile-phone-input"
                    onFocus={() => {
                        setMobileFocus(true);
                    }}
                    style={{ width: '343px', position: 'relative' }}
                />
            </Label>
            {isMobileFocused && (
                <InfoPanel
                    Icon={PhoneIcon}
                    width={['100%', '100%', '343px']}
                    data-qa-id="missing-details-mobile-phone-info-panel"
                >
                    <PlasticText>
                        <Text path="Fields.PhoneNumber.InfoPanel" />
                    </PlasticText>
                </InfoPanel>
            )}
            {errors.MISSING_DETAILS?.mobilePhoneNumber?.message && (
                <HelperText variant="error" data-qa-id="missing-details-mobile-phone-error-message">
                    {errors.MISSING_DETAILS.mobilePhoneNumber.message}
                </HelperText>
            )}
            <HomePhoneNumberWithText />
        </Box>
    );
};

PhoneNumbers.propTypes = {
    Text: PropTypes.func.isRequired,
    theme: PropTypes.objectOf(PropTypes.oneOfType([array, func, object, string]))
};

const PhoneNumbersWithText = withText('Sections.MissingDetails')(PhoneNumbers);

PhoneNumbersWithText.displayName = 'PhoneNumbers';

export default PhoneNumbersWithText;
