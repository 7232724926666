import { Box, Label, CurrencyInput, Button, HelperText, Flex, Input, List } from '@cof/plastic-components';
import luhn from 'luhn';
import PropTypes from 'prop-types';

import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

import { withText } from '../../../text/textStore';

const HorizontalLine = styled.hr`
    ${({ theme }) => css`
        height: 3px;
        border-width: 0;
        color: ${theme.colors.global.grey200};
        background-color: ${theme.colors.global.grey200};
    `};
`;

const ErrorText = {
    CheckCardNo: 'Please check you have entered the card number correctly',
    CardNoRequired: 'A card number is required',
    TransferAmountRequired: 'A transfer amount is required',
    TransferTooLow: 'The minimum transfer amount is £50',
    TransferTooHigh: 'Total amount is over what we can transfer to this card',
    TransferAboveApproved: 'This is over what we can transfer to this card, so we might not be able to accept it all'
};

const BTInput = ({ Text, invalidPANs }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'BT'
    });
    return (
        <div data-qa-id="btinput-fields">
            {fields.map((item, index) => (
                <List key={item.id} pl={['0rem', null, '0rem']}>
                    <li>
                        <Label mt="xs">
                            <Text path="Inputs.CardNumber" />
                            <Controller
                                control={control}
                                name={`BT.${index}.cardNumber`}
                                rules={{
                                    required: ErrorText.CardNoRequired,
                                    maxLength: {
                                        value: 22,
                                        message: ErrorText.CheckCardNo
                                    },
                                    minLength: {
                                        value: 15,
                                        message: ErrorText.CheckCardNo
                                    },
                                    /* eslint-disable consistent-return */
                                    validate: (value) => {
                                        if (
                                            !luhn.validate(value.replace(/\s/g, '')) ||
                                            invalidPANs.includes(value.replace(/\s/g, ''))
                                        ) {
                                            return ErrorText.CheckCardNo;
                                        }
                                    }
                                }}
                                render={({ field: { onChange, name, value } }) => (
                                    <NumberFormat
                                        format={
                                            value.split('')[0] === '3'
                                                ? '#### ###### ##### ####'
                                                : '#### #### #### #### ###'
                                        }
                                        name={name}
                                        value={value}
                                        onValueChange={(values) => {
                                            onChange(values.value);
                                        }}
                                        style={{ width: '343px', marginBottom: '0.8rem' }}
                                        customInput={Input}
                                        data-qa-id={`card-number-${index}`}
                                        errors={errors}
                                        variant={errors.BT?.[index]?.cardNumber?.message ? 'error' : undefined}
                                    />
                                )}
                            />
                        </Label>

                        {errors.BT?.[index]?.cardNumber?.message && (
                            <HelperText mt="xs" variant="error" data-qa-id={`card-error-message-${index}`}>
                                {errors.BT?.[index]?.cardNumber?.message}
                            </HelperText>
                        )}

                        <Label>
                            <Text path="Inputs.TransferAmount" />

                            <Controller
                                control={control}
                                name={`BT.${index}.amount`}
                                rules={{
                                    required: ErrorText.TransferAmountRequired,
                                    validate: {
                                        TransferTooLow: (value) => parseInt(value, 10) >= 50 || ErrorText.TransferTooLow
                                    }
                                }}
                                valueAsNumber
                                render={({ field: { onChange, onBlur, name } }) => (
                                    <CurrencyInput
                                        data-qa-id={`transfer-amount-${index}`}
                                        style={{ width: '343px' }}
                                        name={name}
                                        onValueChange={(InputValue) => onChange(InputValue)}
                                        onBlur={onBlur}
                                        variant={
                                            errors.BT?.[index]?.amount?.type === 'required' ||
                                            errors.BT?.[index]?.amount?.type === 'TransferTooLow'
                                                ? 'error'
                                                : undefined
                                        }
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                    />
                                )}
                            />

                            {errors.BT?.[index]?.amount?.message && (
                                <HelperText mt="xs" data-qa-id={`transfer-error-message-${index}`} variant="error">
                                    <Text
                                        path={
                                            errors.BT?.[index]?.amount?.type === 'TransferTooLow'
                                                ? 'Errors.TransferTooLow'
                                                : 'Errors.TransferAmountRequired'
                                        }
                                    />
                                </HelperText>
                            )}
                        </Label>
                        {fields.length > 1 && (
                            <>
                                <Box py="md">
                                    <Button
                                        id={`bt-cancel-link-${index}`}
                                        data-qa-id={`bt-cancel-link-${index}`}
                                        variant="textLinkInline"
                                        onClick={() => remove(index)}
                                    >
                                        <Text path="Buttons.Cancel" />
                                    </Button>
                                </Box>
                                <HorizontalLine />
                            </>
                        )}
                    </li>
                </List>
            ))}
            {fields.length < 5 && (
                <Flex justifyContent="left" pt={['sm', 'md']} pb="sm">
                    <Button
                        id="bt-add-button"
                        data-qa-id="bt-add-button"
                        variant="secondary"
                        onClick={() => append({ cardNumber: '', amount: '' })}
                    >
                        <Text path="Buttons.Add" />
                    </Button>
                </Flex>
            )}
        </div>
    );
};
BTInput.propTypes = {
    Text: PropTypes.func.isRequired,
    invalidPANs: PropTypes.array.isRequired
};

const BTInputWithText = withText('Sections.BalanceTransfer')(BTInput);

BTInputWithText.displayName = 'BTInput';

export default BTInputWithText;
