import { withText } from '../../../../../../../text/textStore';
import { getSParameterCharAt } from '../../../../../../../utils';
import BaseFeature, { featureComponentPropTypes } from '../BaseFeature';

export const shouldDisplay = ({ brand }) => {
    const instantIssuanceABTestVariant = getSParameterCharAt(1);
    return instantIssuanceABTestVariant === 'B' && brand === 'asos';
};
const InstanceIssuanceFeature = ({ JsxText }) => {
    return (
        <BaseFeature qaId="instant-issuance-ab-test-list-item">
            <JsxText path={'Features.Feature6'} />
        </BaseFeature>
    );
};

InstanceIssuanceFeature.propTypes = featureComponentPropTypes;

const InstanceIssuanceFeatureWithText = withText('Sections.Product')(InstanceIssuanceFeature);

export default InstanceIssuanceFeatureWithText;
