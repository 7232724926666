import { Box } from '@cof/plastic-components';
import PropType from 'prop-types';

import NumberFormat from 'react-number-format';
import { styled, css } from 'styled-components';

import { useJourneyContext } from '../../data/JourneyContext';
import { withText } from '../../text/textStore';
import { getRequestedBTAmount, isValidRequestedBTProduct } from '../../utilities/balanceTransfer/utils';

export const SecondaryHeadingContainer = styled(Box)`
    ${({ theme, brand }) => css`
        background-color: ${theme.colors.global.transparent};
        font-size: ${theme.fontSizes.headingMediumMobile};
        font-weight: ${brand === 'asos' ? theme.fontWeights.regular : theme.fontWeights.semiBold};

        ${theme.breakpoints.mq.tabletMin} {
            font-size: ${theme.fontSizes.headingMediumDesktop};
        }

        ${theme.breakpoints.mq.largeTabletMin} {
            font-size: ${theme.fontSizes.headingMediumDesktop};
        }
    `}
`;

SecondaryHeadingContainer.displayName = 'SecondaryHeading';

const SecondaryHeading = ({ JsxText, Text }) => {
    const { agreementData, brand } = useJourneyContext();
    const validRequestedBTProduct = isValidRequestedBTProduct(agreementData);
    const isGuarantee = agreementData.btDecision === 'Y';

    return (
        <SecondaryHeadingContainer mt="xs" brand={brand}>
            {validRequestedBTProduct ? (
                <span>
                    <JsxText
                        path={isGuarantee ? 'BTGuaranteeLine2' : 'BTNonGuaranteeLine2'}
                        params={{
                            REQUESTED_AMOUNT: (
                                <NumberFormat
                                    value={getRequestedBTAmount(agreementData)}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="£"
                                />
                            )
                        }}
                    />
                </span>
            ) : (
                <span>
                    <Text path="HeadingLine2" />
                </span>
            )}
        </SecondaryHeadingContainer>
    );
};

SecondaryHeading.propTypes = {
    JsxText: PropType.func.isRequired,
    Text: PropType.func.isRequired
};

const SecondaryHeadingWithText = withText('Pages.Landing')(SecondaryHeading);

SecondaryHeadingWithText.displayName = 'SecondaryHeading';

export default SecondaryHeadingWithText;
