import { postOfficeNextTheme } from '@cof/plastic-components';
import { css } from 'styled-components';

import printStyle from '../shared/printStyle';
import customComponents from './componentStyles';
import pages from './pages';

const mergedGlobalStyles = css`
    ${postOfficeNextTheme.globalStyles}
    ${printStyle}
`;

const mergedComponents = {
    ...postOfficeNextTheme.components,
    ...customComponents
};

export default {
    ...postOfficeNextTheme,
    globalStyles: mergedGlobalStyles,
    components: mergedComponents,
    ...pages
};
