import { BaseLogo } from '@cof/plastic-components';

import { ReactComponent as ClearScore } from './clearscore-logo.svg';
import { ReactComponent as CompareTheMarket } from './compare-the-market.svg';
import { ReactComponent as CreditKarma } from './creditkarma-logo.svg';
import { ReactComponent as Experian } from './experian-logo.svg';
import { ReactComponent as MoneyCoUk } from './money-logo.svg';
import { ReactComponent as MoneySuperMarket } from './moneysupermarket-logo.svg';
import { ReactComponent as TotallyMoney } from './totally-money-logo.svg';
import { ReactComponent as USwitch } from './uswitch-logo.svg';

import { ReactComponent as ClearScoreTest } from './clearscore-test-logo.svg';
import { ReactComponent as MSMTest } from './moneysupermarket-test-logo.svg';
import { ReactComponent as CreditKarmaTest } from './creditkarma-test-logo.svg';
import { ReactComponent as ExperianTest } from './experian-test-logo.svg';

const aggregatorsLogoMap = {
    MSM: (
        <BaseLogo
            RenderedLogo={MoneySuperMarket}
            height={[40, null, 60]}
            width={[48, null, 57]}
            logoProps={{ 'aria-label': 'moneysupermarket logo' }}
            data-qa-id="money-supermarket-logo"
        />
    ),
    CLRS: (
        <BaseLogo
            RenderedLogo={ClearScore}
            height={[15, null, 25]}
            width={[113, null, 188]}
            logoProps={{ 'aria-label': 'ClearScore logo' }}
            data-qa-id="clear-score-logo"
        />
    ),
    TOTM: (
        <BaseLogo
            RenderedLogo={TotallyMoney}
            height={[15, null, 25]}
            width={[93, null, 155]}
            logoProps={{ 'aria-label': 'Totally Money logo' }}
            data-qa-id="totally-money-logo"
        />
    ),
    CTM: (
        <BaseLogo
            RenderedLogo={CompareTheMarket}
            height={[25, null, 40]}
            width={[141, null, 226]}
            logoProps={{ 'aria-label': 'Compare the Market logo' }}
            data-qa-id="compare-the-market-logo"
        />
    ),
    USWC: (
        <BaseLogo
            RenderedLogo={USwitch}
            height={[25, null, 40]}
            width={[85, null, 135]}
            logoProps={{ 'aria-label': 'uSwitch logo' }}
            data-qa-id="uswitch-logo"
        />
    ),
    MCU: (
        <BaseLogo
            RenderedLogo={MoneyCoUk}
            height={[20, null, 35]}
            width={[95, null, 167]}
            logoProps={{ 'aria-label': 'Money COUK logo' }}
            data-qa-id="money-couk-logo"
        />
    ),
    EXPR: (
        <BaseLogo
            RenderedLogo={Experian}
            height={[20, null, 35]}
            width={[61, null, 107]}
            logoProps={{ 'aria-label': 'Experian logo' }}
            data-qa-id="experian-logo"
        />
    ),
    CK: (
        <BaseLogo
            RenderedLogo={CreditKarma}
            height={[15, null, 35]}
            width={[62, null, 97]}
            logoProps={{ 'aria-label': 'Credit Karma logo' }}
            data-qa-id="credit-karma-logo"
        />
    )
};

export const aggregatorsExperimentLogoMap = {
    CLRS: (
        <BaseLogo
            RenderedLogo={ClearScoreTest}
            height={[16, null, 26]}
            width={[97, null, 170]}
            logoProps={{ 'aria-label': 'ClearScore logo' }}
            data-qa-id="clearscore-logo"
        />
    ),
    MSM: (
        <BaseLogo
            RenderedLogo={MSMTest}
            height={[16, null, 24]}
            width={[126, null, 189]}
            logoProps={{ 'aria-label': 'MoneySupermarket logo' }}
            data-qa-id="moneysupermarket-logo"
        />
    ),
    CK: (
        <BaseLogo
            RenderedLogo={CreditKarmaTest}
            height={[12, null, 25]}
            width={[81, null, 175]}
            logoProps={{ 'aria-label': 'Credit Karma logo' }}
            data-qa-id="creditkarma-logo"
        />
    ),
    EXPR: (
        <BaseLogo
            RenderedLogo={ExperianTest}
            height={[32, null, 60]}
            width={[73, null, 139]}
            logoProps={{ 'aria-label': 'Experian logo' }}
            data-qa-id="experian-logo"
        />
    )
};

export default aggregatorsLogoMap;
