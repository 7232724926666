import {
    AccordionItem,
    Box,
    Heading,
    InfoIcon,
    List,
    ListItem,
    Text as PlasticText,
    PageRow
} from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import DataPlayback from '../../../../components/DataPlayback/DataPlayback';
import { useJourneyContext } from '../../../../data/JourneyContext';
import EditStage from '../../../../modals/EditStage';
import { withText } from '../../../../text/textStore';
import { trackGenericEvent } from '../../../../utilities/useZuko';
import { shouldExpandCheckDetailsOnPageLoad } from '../../../../utils';
import { isBTProduct as checkIsPTProduct } from '../../../../utilities/product';

const StyledInfoIcon = styled(InfoIcon)`
    ${cssSystem({
        width: ['2.5rem', null, '3.5rem'],
        height: ['2.5rem', null, '3.5rem']
    })}
`;

export const Stage = styled(Box)`
    ${({ theme }) => css`
        margin-bottom: ${theme.space.md};
        background: ${theme.agreement.checkDetails.stage.background};
    `}
`;

const CustomPageRow = styled(PageRow)`
    ${({ theme }) => css`
        padding: ${theme.space.md} ${theme.space.sm} 0;
        ${theme.breakpoints.mq.tabletMin} {
            padding: ${theme.space.lg} ${theme.space.lg} 0;
        }
    `}
`;
const SeperatorPageRow = styled(PageRow)`
    ${({ theme }) => css`
        padding: 0 ${theme.space.sm} 0;
        margin-bottom: 0.8rem;
        ${theme.breakpoints.mq.tabletMin} {
            padding: 0 ${theme.space.lg} 0;
            margin-bottom: 2.4rem;
        }
    `}
`;

Stage.displayName = 'Stage';

const StyledSeparator = styled.div`
    ${({ theme }) => css`
        border-top: 0.3rem solid ${theme.agreement.checkDetails.accordion.separatorColor};
        max-width: 968px;
        margin: auto;
    `}
`;
StyledSeparator.displayName = 'StyledSeparator';

const StyledList = styled(List)`
    ${cssSystem({
        paddingLeft: [0, null, '0.5rem']
    })}
`;

const CheckDetails = ({ requestPending, submitEditRequest, Text }) => {
    const theme = useTheme();
    const { agreementData, sessionId, consumerName, vendorCode } = useJourneyContext();
    const isBTProduct = checkIsPTProduct(agreementData);
    const isExpandedByDefault = shouldExpandCheckDetailsOnPageLoad({ consumerName, vendorCode });

    const editForm = (stageId) => {
        submitEditRequest({
            agreementToken: agreementData.agreementToken,
            stageId,
            sessionId
        });
    };

    const allData = agreementData.stages.reduce(
        (acc, stage) => {
            const stageData = {};

            stage.data.forEach(({ id, value }) => {
                stageData[id] = value;
            });

            return { ...acc, ...stageData };
        },
        { isBT: isBTProduct }
    );

    const [accordionHeading, setAccordionHeading] = useState(
        isExpandedByDefault ? 'Accordion.Heading.WhenOpen' : 'Accordion.Heading.WhenClosed'
    );
    const accordionTriggerProps = {
        onOpen: () => setAccordionHeading('Accordion.Heading.WhenOpen'),
        onClose: () => setAccordionHeading('Accordion.Heading.WhenClosed')
    };
    const [isOpen, setIsOpen] = useState(isExpandedByDefault);
    return (
        <>
            <CustomPageRow data-qa-id="check-details-section">
                <StyledList Icon={StyledInfoIcon} iconColor={theme.agreement.checkDetails.iconColor}>
                    <ListItem style={{ marginTop: 0 }}>
                        <PlasticText
                            size="large"
                            color={theme.agreement.checkDetails.textColor}
                            display="block"
                            mt={['0.1rem', null, '0.3rem']}
                            ml="xs"
                        >
                            <Text path="Intro" />
                        </PlasticText>
                    </ListItem>
                </StyledList>
                <AccordionItem
                    padding={[0, 0, 0]}
                    data-qa-id="check-my-details-accordion"
                    triggerProps={accordionTriggerProps}
                    variant={theme.agreement.checkDetails.accordion.variant}
                    heading={
                        <PlasticText fontWeight={600} color="inherit">
                            <Text path={accordionHeading} />
                        </PlasticText>
                    }
                    isOpen={isOpen}
                    onClick={() => {
                        setIsOpen(!isOpen);
                        trackGenericEvent(isOpen ? 'Check details accordion closed' : 'Check details accordion open');
                    }}
                >
                    {agreementData.stages.map(({ name, id, data }, index, row) => (
                        <Box
                            key={index}
                            paddingBottom={index + 1 === row.length ? '0.1rem' : '0'}
                            marginTop={index === 0 ? ['-1.6rem', 0] : 0}
                            marginLeft={['-2.4rem', '-3.2rem']}
                            marginRight={['-.8rem']}
                        >
                            <Stage
                                data-qa-id={`check-details-stage-${id}`}
                                p={['1.6rem', null, '2.4rem']}
                                borderRadius={['0.4rem', null, '0.8rem']}
                                mt={['1.6rem', 0]}
                                backgroundColor={theme.agreement.checkDetails.accordion?.backgroundColor}
                            >
                                <Heading level="3" textAlign="left" color={theme.agreement.product.titleLine2}>
                                    {name}
                                </Heading>
                                <DataPlayback stageData={data} allData={allData} />
                                <Box mt="sm" textAlign="center">
                                    <EditStage
                                        name={name}
                                        qaId={`check-details-edit-button-${id}`}
                                        disabled={requestPending}
                                        onProceed={() => editForm(id)}
                                    />
                                </Box>
                            </Stage>
                        </Box>
                    ))}
                </AccordionItem>
            </CustomPageRow>
            <SeperatorPageRow>
                <StyledSeparator />
            </SeperatorPageRow>
        </>
    );
};

CheckDetails.propTypes = {
    requestPending: PropTypes.bool.isRequired,
    submitEditRequest: PropTypes.func.isRequired,
    Text: PropTypes.func.isRequired
};

const CheckDetailsWithText = withText('Sections.CheckDetails')(CheckDetails);

CheckDetailsWithText.displayName = 'CheckDetails';

export default CheckDetailsWithText;
