import { List } from '@cof/plastic-components';
import React, { useMemo } from 'react';

import { useJourneyContext } from '../../../../../data/JourneyContext';
import features from './FeatureComponents';

/**
 * Takes the product and other information to determine which features should be displayed
 *
 * @returns All features that should be displayed
 */
const useFeatures = () => {
    const { agreementData, brand } = useJourneyContext();

    const featuresToDisplay = useMemo(
        () =>
            features.reduce((filtered, feature, index) => {
                if (feature.shouldDisplay({ agreementData, brand })) {
                    filtered.push(
                        React.createElement(feature.default, {
                            key: index,
                            agreementData,
                            brand
                        })
                    );
                }
                return filtered;
            }, []),
        [agreementData, brand]
    );

    const Features = () => <List data-qa-id="feature-list">{featuresToDisplay}</List>;
    const featuresCount = featuresToDisplay.length;

    return { Features, featuresCount };
};

export default useFeatures;
