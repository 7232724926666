import { Header as PlasticHeader } from '@cof/plastic-components';
import PropType from 'prop-types';

import styled, { css } from 'styled-components';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';
import LogoComponent from './LogoComponent';
import { useJourneyContext } from '../../data/JourneyContext';

const hasAggregatorLogo = (consumerName) =>
    ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK'].includes(consumerName);

export const StyledHeader = styled(PlasticHeader)`
    ${({
        theme: {
            components: { header }
        },
        showAggregatorLogo,
        backgroundColor
    }) => css`
        background-color: ${backgroundColor ?? header.colors.background.desktop};
        border: ${showAggregatorLogo ? header.colors.aggregators.border : header.colors.border.desktop};
    `};
`;

const Header = ({ appForm }) => {
    const { aggregatorConsumerName } = useJourneyContext();
    const showAggregatorLogo = hasAggregatorLogo(aggregatorConsumerName);
    const { aggregatorExperiment } = useAggregatorBrandExperiment();

    let backgroundColor;
    if (showAggregatorLogo) {
        backgroundColor = 'white';
    }

    if (aggregatorExperiment && appForm) {
        backgroundColor = 'transparent';
    }

    return (
        <StyledHeader showAggregatorLogo={showAggregatorLogo} backgroundColor={backgroundColor}>
            <LogoComponent showAggregatorLogo={showAggregatorLogo} appForm={appForm} />
        </StyledHeader>
    );
};

Header.propTypes = {
    appForm: PropType.bool
};

export default Header;
