import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        iconColor: COLORS.ocean.blue450,
        background: COLORS.ocean.grey50,
        borderedBoxColor: COLORS.global.grey200,
        error: COLORS.ocean.red550,
        scrollBoxBorder: COLORS.global.grey200,
        section: {
            heading: {
                background: COLORS.ocean.blue100
            }
        },
        circle: {
            background: '#00edae'
        },
        checkDetails: {
            editStageButtonVariant: 'textLink',
            iconColor: COLORS.ocean.blue450,
            stage: {
                background: '#FFFFFF'
            },
            accordion: {
                variant: 'dark',
                separatorColor: '#FFFFFF'
            }
        },
        loadingSpinner: {
            background: COLORS.ocean.grey50,
            spinner: COLORS.ocean.blue800
        },
        label: {
            background: COLORS.ocean.grey50
        },
        outerContainer: {
            border: COLORS.ocean.blue450
        },
        product: {
            background: '#FFFFFF',
            coloredDivider: '#00edae',
            cardName: COLORS.ocean.blue800,
            titleLine1: COLORS.ocean.blue800,
            titleLine2: COLORS.ocean.blue800,
            circle: '#00edae',
            icons: COLORS.ocean.blue450,
            lockIconColor: COLORS.ocean.yellow300
        },
        modal: {
            links: 'dark'
        },
        readFollowing: {
            background: COLORS.global.white
        },
        signatureBlock: {
            iconColor: COLORS.ocean.blue450,
            background: COLORS.ocean.blue100,
            border: COLORS.ocean.blue100,
            textVariant: 'light',
            checkbox: COLORS.ocean.blue800
        }
    }
};
