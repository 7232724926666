import NumberFormat from 'react-number-format';

import { withText } from '../../../../../../../text/textStore';
import BaseFeature, { featureComponentPropTypes } from '../BaseFeature';
import { getLineGuranteeCreditLimit } from './utils';
import { useJourneyContext } from '../../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) => {
    const lgLimit = getLineGuranteeCreditLimit(agreementData.product);
    return agreementData.product.isLineGuarantee && lgLimit.maxCreditLimit !== lgLimit.lineGuaranteeCreditLimit;
};

const LineGuaranteeFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const { maxCreditLimit } = getLineGuranteeCreditLimit(agreementData.product);
    return (
        <BaseFeature qaId="line-guarantee-2">
            <JsxText
                path='Features.Feature7'
                params={{
                    MAX_CREDIT_LIMIT: (
                        <NumberFormat value={maxCreditLimit} displayType="text" thousandSeparator prefix="£" />
                    )
                }}
            />
        </BaseFeature>
    );
};

LineGuaranteeFeature.propTypes = featureComponentPropTypes;

const LineGuranteeFeatureWithText = withText('Sections.Product')(LineGuaranteeFeature);

export default LineGuranteeFeatureWithText;
