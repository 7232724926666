import { useEffect, useState } from 'react';

const useTimeout = (interval = 60) => {
    const [isTimedOut, setTimedOut] = useState(false);
    const nowMinutes = Math.round(Date.now() / 60 / 1000);

    useEffect(() => {
        if (!sessionStorage.getItem('sessionStart')) {
            sessionStorage.setItem('sessionStart', nowMinutes);
        }

        const sessionStart = parseInt(sessionStorage.getItem('sessionStart') || 0, 10);
        const remainingTime = nowMinutes - sessionStart >= interval ? 0 : interval - (nowMinutes - sessionStart);

        setTimeout(
            () => {
                if (!document.title.includes('Session expired -')) {
                    document.title = `Session expired - ${document.title}`;
                }
                setTimedOut(true);
            },
            remainingTime * 60 * 1000
        );
    }, [isTimedOut, interval, nowMinutes]);

    return isTimedOut;
};

export default useTimeout;
