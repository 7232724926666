import NumberFormat from 'react-number-format';

import { withText } from '../../../../../../text/textStore';
import { isGreaterThanEqualToZero, isGreaterThanZero } from '../../../../../../utilities/product';
import BaseFeature, { featureComponentPropTypes } from './BaseFeature';
import { useJourneyContext } from '../../../../../../data/JourneyContext';

export const shouldDisplay = ({ agreementData }) => {
    const purchaseMonths = agreementData.product.interest.teaserPeriods?.purchaseTeaserMonths;
    const purchaseTeaser = agreementData.product.interest.compound?.purchaseIntroductory;
    return isGreaterThanZero(purchaseMonths) && isGreaterThanEqualToZero(purchaseTeaser);
};

const TeaserFeature = ({ JsxText }) => {
    const { agreementData } = useJourneyContext();
    const purchaseMonths = agreementData.product.interest.teaserPeriods?.purchaseTeaserMonths;
    const purchaseTeaser = agreementData.product.interest.compound?.purchaseIntroductory;

    return (
        <BaseFeature qaId="purchase-introductory-rate">
            <JsxText
                path='Features.Feature5'
                params={{
                    INTRODUCTORY_RATE: (
                        <NumberFormat value={purchaseTeaser} displayType="text" decimalScale={0} suffix="%" />
                    ),
                    PURCHASE_MONTHS: <span>{purchaseMonths}</span>
                }}
            />
        </BaseFeature>
    );
};

TeaserFeature.propTypes = featureComponentPropTypes;

const TeaserFeatureWithText = withText('Sections.Product')(TeaserFeature);

export default TeaserFeatureWithText;
