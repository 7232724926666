import PropTypes from 'prop-types';
import { Component, createRef } from 'react';

import ControlledModal from './ControlledModal';

class Modal extends Component {
    modalTriggerRef = createRef();

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    onOpen = () => {
        this.setState({ isOpen: true });
    };

    onClose = () => {
        this.setState({ isOpen: false });
    };

    onAfterClose = () => {
        if (!this.modalTriggerRef.current) {
            throw new Error(
                "Accessibility Violation: Couldn't find a modal trigger to focus, please add " +
                    'focusableElementRef as a ref to the modal trigger, this behaviour is required for accessibility.'
            );
        }
        this.modalTriggerRef.current.focus();
        const { onAfterClose } = this.props;
        if (onAfterClose) {
            onAfterClose();
        }
    };

    render() {
        const { modalTrigger, modalContent, ariaLabel, ariaLabelledby } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                {modalTrigger({ onOpen: this.onOpen, focusableElementRef: this.modalTriggerRef })}
                <ControlledModal
                    onAfterClose={this.onAfterClose}
                    isOpen={isOpen}
                    onRequestClose={this.onClose}
                    modalContent={modalContent}
                    ariaLabel={ariaLabel}
                    ariaLabelledby={ariaLabelledby}
                />
            </>
        );
    }
}

Modal.propTypes = {
    modalTrigger: PropTypes.func.isRequired,
    modalContent: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    onAfterClose: PropTypes.func
};

export default Modal;
