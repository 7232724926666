import { Button, Heading, Paragraph } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled, { css, useTheme } from 'styled-components';

import Modal from '../../components/Modal';
import CloseButton from '../../components/Modal/CloseButton';
import ModalLink from '../../components/ModalLink';
import { withText } from '../../text/textStore';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer';

const PrintButton = styled(Button)`
    ${({ theme }) => css`
        display: block;
        margin: ${theme.space.lg} auto 0;
    `}
`;

PrintButton.displayName = 'PrintButton';

const onBTTermsModalOpen = (buttonText, onOpen) => {
    pushToDataLayer({
        event: EVENTS.MODAL_OPEN,
        modalName: buttonText
    });
    onOpen();
};

const BTTerms = ({ Text }) => {
    const theme = useTheme();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <Modal
            modalContent={({ onClose, focusableElementRef }) => (
                <div ref={componentRef}>
                    <CloseButton onClick={onClose} ref={focusableElementRef} />
                    <Heading level="3" textAlign="left" mb={['xs', 'sm', 'md']}>
                        <Text path="Heading" />
                    </Heading>
                    <Paragraph text-align="left" data-qa-id="BT-Terms-modal-paragraph1">
                        <Text path="Paragraphs.1" />
                    </Paragraph>
                    <Paragraph data-qa-id="BT-Terms-modal-paragraph2">
                        <Text path="Paragraphs.2" />
                    </Paragraph>
                    <Paragraph data-qa-id="BT-Terms-modal-paragraph3">
                        <Text path="Paragraphs.3" />
                    </Paragraph>
                    <Paragraph data-qa-id="BT-Terms-modal-paragraph4">
                        <Text path="Paragraphs.4" />
                    </Paragraph>
                    <Paragraph data-qa-id="BT-Terms-modal-paragraph5">
                        <Text path="Paragraphs.5" />
                    </Paragraph>
                    <Paragraph data-qa-id="BT-Terms-modal-paragraph6">
                        <Text path="Paragraphs.6" />
                    </Paragraph>
                    <PrintButton onClick={handlePrint} data-qa-id="BT-Terms-Print-Button">
                        Print
                    </PrintButton>
                </div>
            )}
            modalTrigger={({ onOpen, focusableElementRef }) => (
                <ModalLink
                    qaId="BT-Terms-modal-button"
                    variant="dark"
                    color={theme.agreement.linkColorDark}
                    onClick={() => onBTTermsModalOpen(Text({ path: 'TriggerText' }), onOpen)}
                    ref={focusableElementRef}
                >
                    <Text path="TriggerText" />
                </ModalLink>
            )}
            ariaLabel={Text({ path: 'Heading' })}
        />
    );
};

BTTerms.propTypes = {
    Text: PropTypes.func.isRequired
};

const BTTermsWithText = withText('Modals.BTTerms')(BTTerms);

BTTermsWithText.displayName = 'BTTerms';

export default BTTermsWithText;
