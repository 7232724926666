import { asosTheme, THEME_CONSTANTS as TC } from '@cof/plastic-components';

export default {
    ...asosTheme.components.header,
    colors: {
        background: {
            mobile: TC.COLORS.global.grey50,
            desktop: TC.COLORS.global.grey50
        },
        border: {
            mobile: TC.COLORS.global.grey50,
            desktop: TC.COLORS.global.grey50
        }
    }
};
