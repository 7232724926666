import { css } from 'styled-components';

const content = () => css`
    display: flex;
    flex-flow: row-reverse wrap;
    position: relative;
    z-index: 1;
`;

const imageWrapper = ({ theme }) => css`
    flex: 1 1 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: ${theme.space.xs};

    border-color: ${theme.agreement.product.circle};

    ${theme.breakpoints.mq.tabletMin} {
        flex: 1 1 50%;
        padding: ${theme.space.md} ${theme.space.md} ${theme.space.md} ${theme.space.lg};
    }
`;

const card = ({ theme }) => css`
    max-height: 80%;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
    display: block;
    object-fit: contain;

    ${theme.breakpoints.mq.tabletMin} {
        max-height: initial;
    }
`;

const border = ({ theme }) => css`
    border-image-slice: 1;
    border-width: 5px;
    border: none;

    ${theme.components.celebration.primary}
`;

const featureContainer = () => css`
    flex: 1 1 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
`;

const circle = () => css`
    display: none;
`;

const celebrationBox = ({ theme }) => css`
    padding: 0.6rem;
    position: absolute;
    z-index: 0;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${theme.colors.global.grey50};
`;

const styledHeading = () => css`
    margin-top: 1.2rem;
`;

const styledCircle = ({ theme }) => css`
    transform: scale(0.48) translate(46rem, 16rem);

    ${`@media (min-width: 440px)`} {
        transform: scale(0.56) translate(45rem, 15rem);
    }
    ${theme.breakpoints.mq.tabletMin} {
        transform: scale(0.76) translate(26rem, 10rem);
    }
    ${theme.breakpoints.mq.largeTabletMin} {
        transform: scale(0.9) translate(10rem, 10rem);
    }
`;

export default {
    content,
    featureContainer,
    circle,
    card,
    imageWrapper,
    border,
    celebrationBox,
    styledHeading,
    styledCircle
};
