import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { ERROR_TYPE } from '../../utilities/constants';
import { addNewRelicError } from '../../utilities/newRelic';
import ClientError from './Variants/ClientError';
import LoadAgreementError from './Variants/LoadAgreement';
import SubmitAgreementError from './Variants/SubmitAgreement';
import Header from '../../components/Header';

const TechnicalError = ({ errorType = ERROR_TYPE.CLIENT_ERROR }) => {
    useEffect(() => {
        addNewRelicError(errorType, { sawError: true });
    }, [errorType]);

    sessionStorage.clear();

    let ErrorComponent;

    switch (errorType) {
        case ERROR_TYPE.LOAD_AGREEMENT:
            ErrorComponent = LoadAgreementError;
            break;
        case ERROR_TYPE.SUBMIT_AGREEMENT:
            ErrorComponent = SubmitAgreementError;
            break;
        default:
            ErrorComponent = ClientError;
    }

    return (
        <>
            <Header />
            <ErrorComponent />
        </>
    );
};

TechnicalError.propTypes = {
    errorType: PropTypes.oneOf(Object.values(ERROR_TYPE))
};

TechnicalError.displayName = 'TechnicalError';

export default TechnicalError;
