import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    agreement: {
        heading1: COLORS.global.black,
        iconColor: COLORS.very.pink500,
        section: {
            heading: {
                background: COLORS.global.grey100
            }
        },
        circle: {
            background: COLORS.very.pink500
        },
        otherThingsSection: {
            listIcon: COLORS.very.pink500
        },
        checkDetails: {
            editStageButtonVariant: 'textLink',
            iconColor: COLORS.very.pink500,
            stage: {
                background: COLORS.global.white
            },
            accordion: {
                variant: 'dark',
                separatorColor: COLORS.global.white
            }
        },
        background: COLORS.global.grey50,
        borderedBoxColor: COLORS.global.grey100,
        error: COLORS.very.red550,
        heading2: COLORS.global.black,
        heading3: COLORS.global.black,
        linkColor: COLORS.global.black,
        listItem: COLORS.global.black,
        listIcon: COLORS.very.pink500,
        modalHeading: COLORS.global.black,
        text: COLORS.global.black,
        scrollBoxBorder: COLORS.global.grey200,
        loadingSpinner: {
            background: COLORS.global.grey50,
            spinner: COLORS.very.pink500
        },
        label: {
            background: COLORS.global.grey50
        },
        outerContainer: {
            border: COLORS.very.pink500
        },
        product: {
            background: COLORS.global.white,
            coloredDivider: COLORS.very.pink500,
            cardName: COLORS.global.black,
            titleLine1: COLORS.global.black,
            titleLine2: COLORS.global.black,
            circle: COLORS.global.grey100,
            bulletPoints: COLORS.global.black,
            icons: COLORS.very.pink500,
            lockIconColor: COLORS.very.yellow250
        },
        modal: {
            links: 'dark'
        },
        readFollowing: {
            background: COLORS.global.white
        },
        signatureBlock: {
            iconColor: COLORS.very.pink500,
            background: '#FCE6F2', // Colour not approved for use elsewhere, not to be added to Plastic
            border: COLORS.global.transparent,
            checkbox: COLORS.global.black,
            textVariant: 'light'
        }
    }
};
