import { Box, Radio, RadioGroup, Paragraph, Text as PlasticText, THEME_CONSTANTS } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import BalanceTransferEnabled from '../../../../components/BalanceTransfer';
import Section from '../../../../components/Section';
import BTTermsWithText from '../../../../modals/BTTerms/BTTerms';
import { withText } from '../../../../text/textStore';
import { Legend } from '../../../../components/common/common';

/**
 * The Balance Transfer section - shows on journeys for Balance Transfer cards
 */
const BalanceTransfer = ({ JsxText, Text, invalidPANs, experimentVariant }) => {
    const { clearErrors, resetField } = useFormContext();

    const btExperimentChars = ['O', 'N', 'T'];
    const [btEnabled, setBTEnabled] = useState(!btExperimentChars.includes(experimentVariant));

    return (
        <Section qaId="balance-transfer-section" Text={Text}>
            <Box bg={THEME_CONSTANTS.COLORS.global.white} pt={['xs', 'sm']} pb={['md', 'lg']} px={['sm', 'md']}>
                <Legend as="p" data-qa-id="balance-transfer-subheading">
                    <Text path="SubHeading" />
                </Legend>
                <Paragraph size={THEME_CONSTANTS.TEXT_SIZES.SMALL}>
                    <Text path="Paragraph1" />
                </Paragraph>
                <Box py="xs">
                    <RadioGroup
                        defaultValue={btExperimentChars.includes(experimentVariant) ? '' : 'yes'}
                        variant="square"
                        name="bt-enabled-radio"
                        optionWrapperProps={{ width: '14.2rem' }}
                    >
                        <Radio
                            value="yes"
                            onClick={() => {
                                setBTEnabled(true);
                                clearErrors('BT');
                                resetField('BT');
                            }}
                        >
                            <PlasticText data-qa-id="bt-yes-button">Yes</PlasticText>
                        </Radio>

                        <Radio
                            value="no"
                            onClick={() => {
                                setBTEnabled(false);
                                clearErrors('BT');
                                resetField('BT');
                            }}
                        >
                            <PlasticText data-qa-id="bt-no-button">No</PlasticText>
                        </Radio>
                    </RadioGroup>
                </Box>

                {btEnabled && <BalanceTransferEnabled invalidPANs={invalidPANs} />}

                <Box>
                    <PlasticText>
                        <JsxText
                            path="Paragraph3.Text"
                            params={{
                                BTTerms: <BTTermsWithText />
                            }}
                        />
                    </PlasticText>
                </Box>
            </Box>
        </Section>
    );
};

BalanceTransfer.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired,
    invalidPANs: PropTypes.array.isRequired,
    experimentVariant: PropTypes.string
};

const BalanceTransferWithText = withText('Sections.BalanceTransfer')(BalanceTransfer);

BalanceTransferWithText.displayName = 'BalanceTransfer';
export default BalanceTransferWithText;
