/* eslint-disable react/jsx-props-no-spreading */
import {
    Branded3dCardImage,
    LoadableCapitalOne3dCardImage,
    LoadableLuma3dCardImage,
    LoadableVery3dCardImage,
    LoadableLittlewoods3dCardImage,
    LoadableOcean3dCardImage,
    LoadableThinkMoney3dCardImage,
    LoadablePostOffice3dCardImage,
    LoadableASOS3dHorizontalImage
} from '@cof/plastic-components';

const brandConfig = {
    capitalOne: LoadableCapitalOne3dCardImage,
    littlewoods: LoadableLittlewoods3dCardImage,
    luma: LoadableLuma3dCardImage,
    ocean: LoadableOcean3dCardImage,
    thinkmoney: LoadableThinkMoney3dCardImage,
    postoffice: LoadablePostOffice3dCardImage,
    very: LoadableVery3dCardImage,
    asos: LoadableASOS3dHorizontalImage
};

// pass null for the allowList as this will cause it to pick up the allowList from the top level brand provider
const Card = (props) => <Branded3dCardImage width="30.2rem" brandConfig={brandConfig} allowList={null} {...props} />;

export default Card;
