import {
    Checkbox,
    Box,
    Label,
    Link,
    List,
    ListItem,
    Paragraph,
    Text as PlasticText,
    THEME_CONSTANTS
} from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { styled, useTheme } from 'styled-components';

import Section from '../../../../components/Section';
import FAQs from '../../../../modals/FAQs/FAQs';
import SummaryBox from '../../../../modals/SummaryBox';
import { withText } from '../../../../text/textStore';
import { PREFERENCE } from '../../../../utilities/constants';
import pushToDataLayer, { EVENTS } from '../../../../utilities/dataLayer';
import { useJourneyContext } from '../../../../data/JourneyContext';

const StyledLabel = styled(Label)`
    width: auto;
    margin: 0;
    display: inline-flex;
`;
StyledLabel.displayName = 'StyledLabel';

const checkboxChangeEvent = (fieldName, value) => {
    const eventAction = value ? 'checked' : 'unchecked';
    pushToDataLayer({
        event: EVENTS.CHECKBOX_CHANGE,
        eventAction,
        fieldName
    });
};

const marketingCheckboxPath = 'MarketingCheckbox';
const onlineStatementsCheckbox = 'OnlineStatementsCheckbox';

const OtherThingsSection = ({ Text, JsxText }) => {
    const { agreementData } = useJourneyContext();
    const theme = useTheme();
    const { register } = useFormContext();

    return (
        <Section qaId="other-things-section" Text={Text}>
            <Box bg={THEME_CONSTANTS.COLORS.black} pt={['xs', 'sm']} pb="md" px={['sm', 'md']}>
                <Paragraph fontWeight="600">
                    <Text path="Paragraph0" />
                </Paragraph>
                <List>
                    <ListItem iconColor={theme.agreement.otherThingsSection?.listIcon}>
                        <PlasticText>
                            <Text path="List0.0" />
                        </PlasticText>
                    </ListItem>
                    <ListItem iconColor={theme.agreement.otherThingsSection?.listIcon}>
                        <PlasticText>
                            <Text path="List0.1" />
                        </PlasticText>
                    </ListItem>
                </List>
                <Paragraph>
                    <Text path="Paragraph1" />
                </Paragraph>
                <List>
                    <ListItem iconColor={theme.agreement.otherThingsSection?.listIcon}>
                        <PlasticText>
                            <Text path="List1.0" />
                        </PlasticText>
                    </ListItem>
                </List>
                {agreementData?.disclosures && (
                    <Paragraph>
                        <JsxText
                            path="Paragraph2"
                            params={{
                                SUMMARY_BOX: <SummaryBox />,
                                FAQs: <FAQs />
                            }}
                        />
                    </Paragraph>
                )}
                <Paragraph>
                    <JsxText
                        path="Paragraph3.Text"
                        params={{
                            PRIVACY_POLICY: (
                                <Link
                                    data-qa-id="privacy-policy-link"
                                    href={Text({ path: 'Paragraph3.PrivacyPolicyLink' })}
                                    external
                                >
                                    <Text path="Paragraph3.PrivacyPolicy" />
                                </Link>
                            )
                        }}
                    />
                </Paragraph>
                <StyledLabel
                    paddingY="xs"
                    htmlFor={`${PREFERENCE.MARKETING_CONTACT_PERMISSION}-checkbox`}
                    data-qa-id={`${PREFERENCE.MARKETING_CONTACT_PERMISSION}-label`}
                >
                    <Checkbox
                        {...register(PREFERENCE.MARKETING_CONTACT_PERMISSION, {
                            onChange: ({ target: { checked } }) => {
                                checkboxChangeEvent(Text({ path: marketingCheckboxPath }), checked);
                            }
                        })}
                    />
                    <PlasticText ml="sm">
                        <Text path={marketingCheckboxPath} />
                    </PlasticText>
                </StyledLabel>
                <StyledLabel
                    paddingY="xs"
                    htmlFor={`${PREFERENCE.ONLINE_STATEMENTS}-checkbox`}
                    data-qa-id={`${PREFERENCE.ONLINE_STATEMENTS}-label`}
                >
                    <Checkbox
                        {...register(PREFERENCE.ONLINE_STATEMENTS, {
                            onChange: ({ target: { checked } }) => {
                                checkboxChangeEvent(Text({ path: onlineStatementsCheckbox }), checked);
                            }
                        })}
                    />
                    <PlasticText ml="sm">
                        <Text path={onlineStatementsCheckbox} />
                    </PlasticText>
                </StyledLabel>
            </Box>
        </Section>
    );
};

OtherThingsSection.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const OtherThingsSectionWithText = withText('Sections.OtherThings')(OtherThingsSection);
OtherThingsSectionWithText.displayName = 'OtherThingsSection';

export default OtherThingsSectionWithText;
