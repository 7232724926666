import { useState } from 'react';
import {
    AccordionItem,
    DocumentIcon,
    List,
    ListItem,
    PageRow,
    Text as PlasticText,
    Box,
    useBrand
} from '@cof/plastic-components';
import PropType from 'prop-types';
import cssSystem from '@styled-system/css';
import { styled, css, useTheme } from 'styled-components';
import SimpleSection from '../../pages/Agreement/Sections/SimpleSection';
import { withText } from '../../text/textStore';
import { trackGenericEvent } from '../../utilities/useZuko';

const StyledList = styled(List)`
    ${cssSystem({
        paddingLeft: [0, null, '0.5rem']
    })}
`;

const StyledDocumentIcon = styled(DocumentIcon)`
    ${cssSystem({
        width: ['2.5rem', null, '3.5rem'],
        height: ['2.5rem', null, '3.5rem']
    })}
`;

const SeperatorPageRow = styled(PageRow)`
    ${({ theme }) => css`
        padding: 0;
        margin-bottom: 0.8rem;
        ${theme.breakpoints.mq.tabletMin} {
            padding: 0;
            margin-bottom: 2.4rem;
        }
    `}
`;

const StyledSeparator = styled.div`
    ${({ theme }) => css`
        border-top: 0.3rem solid ${theme.agreement.checkDetails.accordion.separatorColor};
        max-width: 968px;
        margin: 0;
    `}
`;
StyledSeparator.displayName = 'StyledSeparator';

const CustomPageRow = styled(PageRow)`
    ${({ theme }) => css`
        padding: ${theme.space.md} ${theme.space.sm} 0;
        ${theme.breakpoints.mq.tabletMin} {
            padding: ${theme.space.lg} ${theme.space.lg} 0;
        }
    `}
`;

const PrecontractInformationSectionExperiment = ({ Text, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();

    return (
        <CustomPageRow data-qa-id="precontract-information-section-experiment">
            <StyledList Icon={StyledDocumentIcon} iconColor={theme.agreement.checkDetails.iconColor}>
                <ListItem style={{ marginTop: 0 }}>
                    <PlasticText
                        size="large"
                        color={theme.agreement.checkDetails.textColor}
                        display="block"
                        mt={['0.1rem', null, '0.3rem']}
                        ml="xs"
                    >
                        <Text path="Title" />
                    </PlasticText>
                </ListItem>
            </StyledList>
            <AccordionItem
                padding={[0, 0, 0]}
                data-qa-id="pre-contract-information-accordion"
                variant={theme.agreement.checkDetails.accordion.variant}
                heading={
                    <PlasticText data-qa-id="pcci-accordion-header" fontWeight={600} color="inherit">
                        <Text path={isOpen ? 'Open' : 'Closed'} />
                    </PlasticText>
                }
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen(!isOpen);
                    trackGenericEvent(isOpen ? 'PCCI accordion closed' : 'PCCI accordion open');
                }}
            >
                <Box marginLeft={['-2.4rem', '-3.2rem']} marginRight={['-.8rem']}>
                    <SimpleSection
                        qaId="precontract-information-simple-section"
                        {...props}
                        Text={Text}
                        indent={false}
                        showHeading={false}
                    />
                </Box>
            </AccordionItem>
            <SeperatorPageRow mt={isOpen ? 'sm' : 'unset'}>
                <StyledSeparator />
            </SeperatorPageRow>
        </CustomPageRow>
    );
};

PrecontractInformationSectionExperiment.propTypes = {
    Text: PropType.func.isRequired
};

const PrecontractInformationSection = (props) => {
    const brand = useBrand();
    const isInitialPCCIRolloutBrand = ['capitalOne', 'ocean', 'thinkmoney', 'postoffice', 'luma', 'asos'].includes(
        brand
    );

    const PcciComponent = isInitialPCCIRolloutBrand
        ? withText('Sections.PrecontractInformation.AccordionExperiment')(PrecontractInformationSectionExperiment)
        : withText('Sections.PrecontractInformation')(SimpleSection);

    return <PcciComponent {...props} />;
};

PrecontractInformationSection.displayName = 'PrecontractInformationSection';

export default PrecontractInformationSection;
