import * as AprComponent from './APR';
import * as BtComponent from './BT/BT';
import * as BtComponent2 from './BT/BT2';
import * as LineGuaranteeComponentLimit3 from './CreditLimit';
import * as InstanceIssuance from './InstantIssuance/InstanceIssuance';
import * as LineGuaranteeComponentLimit from './LineGuarantee/LineGuaranteeLimit';
import * as LineGuaranteeComponentLimit2 from './LineGuarantee/LineGuaranteeLimit2';
import * as TeaserComponent from './TeaserMonths';

export default [
    LineGuaranteeComponentLimit,
    LineGuaranteeComponentLimit2,
    LineGuaranteeComponentLimit3,
    BtComponent,
    BtComponent2,
    TeaserComponent,
    AprComponent,
    InstanceIssuance
]; // export in the order they should appear
