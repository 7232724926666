import {
    Box,
    Button,
    Flex,
    Heading,
    List,
    ListItem,
    PageRowFullHeight,
    Text as PlasticText,
    THEME_CONSTANTS as TC
} from '@cof/plastic-components';
import PropTypes from 'prop-types';

import { withText } from '../../../../text/textStore';
import { ERROR_TYPE } from '../../../../utilities/constants';
import { useTheme } from 'styled-components';

const ClientError = ({ Text }) => {
    const {
        agreement: { background, errorHeading, text: textColor, listItem }
    } = useTheme();
    return (
        <PageRowFullHeight data-qa-id="client-technical-error" wrapperBg={background}>
            <Heading
                data-qa-id="tech-error-heading"
                level="1"
                color={errorHeading}
                textAlign={['left', null, 'center']}
            >
                <Text path="Title" />
            </Heading>
            <Box
                data-qa-id="tech-error-message"
                paddingY={['xs', 'sm', 'md']}
                maxWidth={TC.SCREEN_WIDTHS.SIX_COLUMN}
                mx="auto"
            >
                <PlasticText data-qa-id="tech-error-message-line1" color={textColor}>
                    <Text path="List.Description" />
                </PlasticText>
                <List iconColor={listItem}>
                    <ListItem>
                        <PlasticText data-qa-id="tech-error-message-line2" color={textColor}>
                            <Text path="List.Item1" />
                        </PlasticText>
                    </ListItem>
                    <ListItem>
                        <PlasticText data-qa-id="tech-error-message-line3" color={textColor}>
                            <Text path="List.Item2" />
                        </PlasticText>
                    </ListItem>
                </List>
            </Box>
            <Flex justifyContent="center">
                <Button data-qa-id="tech-error-message-button" as="a" href={Text({ path: 'Button.URL' })}>
                    <Text path="Button.Text" />
                </Button>
            </Flex>
        </PageRowFullHeight>
    );
};

ClientError.propTypes = {
    Text: PropTypes.func.isRequired
};

const ClientErrorWithText = withText(`Pages.TechnicalError.${ERROR_TYPE.CLIENT_ERROR}`)(ClientError);

ClientErrorWithText.displayName = 'ClientError';

export default ClientErrorWithText;
