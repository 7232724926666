import { Button } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled, { css, useTheme } from 'styled-components';

import Modal from '../../components/Modal';
import CloseButton from '../../components/Modal/CloseButton';
import ModalLink from '../../components/ModalLink';
import { withText } from '../../text/textStore';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer';
import { useJourneyContext } from '../../data/JourneyContext';

const StyledHtml = styled.div`
    ${({ theme }) => theme.components.disclosuresHtml};
    @media print {
        margin: 5mm;
    }
`;

const PrintButton = styled(Button)`
    ${({ theme }) => css`
        display: block;
        margin: ${theme.space.lg} auto 0;
    `}

    @media print {
        display: none;
    }
`;

PrintButton.displayName = 'PrintButton';

const onSummaryBoxModalOpen = (buttonText, onOpen) => {
    pushToDataLayer({
        event: EVENTS.MODAL_OPEN,
        modalName: buttonText
    });
    onOpen();
};

const SummaryBox = ({ Text }) => {
    const { agreementData } = useJourneyContext();
    const html = agreementData.disclosures.summaryBox;
    const theme = useTheme();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <Modal
            modalContent={({ onClose, focusableElementRef }) => (
                <div>
                    <CloseButton onClick={onClose} ref={focusableElementRef} />
                    <StyledHtml dangerouslySetInnerHTML={{ __html: html }} ref={componentRef} />
                    <PrintButton onClick={handlePrint}>Print</PrintButton>
                </div>
            )}
            modalTrigger={({ onOpen, focusableElementRef }) => (
                <ModalLink
                    qaId="summary-box-modal-button"
                    variant="dark"
                    color={theme.agreement.linkColorDark}
                    iconColor={theme.agreement.linkIconColor}
                    onClick={() => onSummaryBoxModalOpen(Text({ path: 'TriggerText' }), onOpen)}
                    ref={focusableElementRef}
                >
                    <Text path="TriggerText" />
                </ModalLink>
            )}
            ariaLabel={Text({ path: 'Heading' })}
        />
    );
};

SummaryBox.propTypes = {
    Text: PropTypes.func.isRequired
};

const SummaryBoxWithText = withText('Modals.SummaryBox')(SummaryBox);

SummaryBoxWithText.displayName = 'SummaryBox';

export default SummaryBoxWithText;
