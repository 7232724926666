import { Box, HelperText, Radio, RadioGroup, Text as PlasticText } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { withText } from '../../../text/textStore';
import { useJourneyContext } from '../../../data/JourneyContext';

const Title = ({ Text }) => {
    const { agreementData } = useJourneyContext();
    const { titleOptions } = agreementData;

    const {
        register,
        formState: { errors }
    } = useFormContext();

    const { name: payMethod, ...radioProps } = register('MISSING_DETAILS.title', {
        required: Text({ path: 'Errors.Title.Required' })
    });

    return (
        <Box data-qa-id="missing-details-title-box">
            <PlasticText data-qa-id="missing-details-title-label" fontWeight="medium">
                <Text path="Fields.Title" />
            </PlasticText>
            <RadioGroup name={payMethod} variant="square" data-qa-id="radio-group-title" mt="xs">
                {titleOptions.map((option, index) => (
                    <Radio value={option} {...radioProps} key={index}>
                        <PlasticText>{option}</PlasticText>
                    </Radio>
                ))}
            </RadioGroup>
            <Box>
                {errors.MISSING_DETAILS?.title?.message && (
                    <HelperText variant="error" data-qa-id="missing-details-title-error-message" mt="0">
                        {errors.MISSING_DETAILS?.title?.message}
                    </HelperText>
                )}
            </Box>
        </Box>
    );
};

Title.propTypes = {
    Text: PropTypes.func.isRequired
};

const TitleWithText = withText('Sections.MissingDetails')(Title);

TitleWithText.displayName = 'Title';

export default TitleWithText;
