import {
    Box,
    Button,
    Flex,
    Heading,
    Paragraph,
    Text as PlasticText,
    THEME_CONSTANTS,
    InfoIcon,
    DownloadIcon
} from '@cof/plastic-components';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled, { css, useTheme } from 'styled-components';

import ScrollBox from '../../../../components/ScrollBox';
import Section from '../../../../components/Section';
import { withText } from '../../../../text/textStore';
import { addNewRelicPageAction } from '../../../../utilities/newRelic';
import { useJourneyContext } from '../../../../data/JourneyContext';

const SignatureBlockBox = styled(Box)`
    ${({ theme }) => css`
        border: 0.2rem solid ${theme.agreement.signatureBlock.border};
    `};
`;

SignatureBlockBox.displayName = 'SignatureBlockBox';

const StyledHtml = styled.div`
    ${({ theme }) => theme.components.disclosuresHtml};
    @media print {
        margin: 5mm;
    }
`;
const SubmitButton = styled(Button)`
    ${({ theme }) => theme.components.submitButton}
`;

const SectionWrap = styled('div')`
    ${({ theme }) => css`
        margin: 0 -1.6rem -2.4rem -1.6rem;
        padding: 0 1.6rem 2.4rem 1.6rem;
        background-color: ${theme.agreement.signatureBlock.background};

        ${theme.breakpoints.mq.tabletMin} {
            margin: 0 -2.4em -3.2em -2.4em;
            padding: 0 2.4em 3.2em 2.4em;
        }

        ${theme.breakpoints.mq.tabletMax} {
            margin: 0 -2.4em -3.2em -2.4em;
            padding: 0 2.4em 3.2em 2.4em;
        }
    `}
`;

const AgreementSign = ({ Text, JsxText }) => {
    const {
        agreementData: {
            disclosures: { termsAndConditions, dateGenerated }
        }
    } = useJourneyContext();
    const theme = useTheme();
    const parsedDate = dayjs(dateGenerated).format('DD/MM/YYYY');
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const DateTextParams = {
        DATE: <PlasticText size={THEME_CONSTANTS.TEXT_SIZES.MEDIUM}>{parsedDate}</PlasticText>
    };

    return (
        <Box pb={['md', null, 'lg']}>
            <Section qaId="finish-section" Text={Text}>
                <Box bg={THEME_CONSTANTS.COLORS.global.white} pb={['md', 'lg']} pt={['xs', 'sm']} px={['sm', 'md']}>
                    <Paragraph textAlign="left" mb={['xs', 'sm']}>
                        <Text path="Heading" />
                    </Paragraph>
                    <ScrollBox
                        height="27.4rem"
                        tabIndex="0"
                        data-qa-id="html-scrollbox"
                        bg={THEME_CONSTANTS.COLORS.black}
                        p="sm"
                    >
                        <PlasticText>
                            <StyledHtml dangerouslySetInnerHTML={{ __html: termsAndConditions }} ref={componentRef} />
                        </PlasticText>
                    </ScrollBox>
                    <Flex justifyContent="center">
                        <Button
                            id="download-my-agreement-button"
                            data-qa-id="download-my-agreement-button"
                            onClick={handlePrint}
                            variant="textLink"
                            Icon={DownloadIcon}
                        >
                            <Text path="Button" />
                        </Button>
                    </Flex>
                    <SignatureBlockBox mx={['-1.6rem', '-2.4rem']} bg={theme.agreement.signatureBlock.background}>
                        <Heading
                            level="2"
                            size="small"
                            textAlign="left"
                            p={['sm', 'md', 'md']}
                            color={theme.agreement.signatureBlock.textColor}
                        >
                            <Text path="FinishYourApplication" />
                        </Heading>
                    </SignatureBlockBox>
                    <SectionWrap>
                        <Flex
                            maxWidth="55.9rem"
                            width="100%"
                            margin="auto"
                            justifyContent="center"
                            pt={['sm', 'md']}
                            pb={['sm']}
                        >
                            <InfoIcon
                                height="2.4rem"
                                minWidth="2.4rem"
                                marginTop="-0.2rem"
                                marginRight="1rem"
                                fontWeight="semiBold"
                                color={theme.agreement.signatureBlock.iconColor}
                            />
                            <Paragraph fontWeight="semiBold" pb="0" mb="0">
                                <Text path="Finish" />
                            </Paragraph>
                        </Flex>
                        <Flex width={['100%', '559px']} margin="auto" justifyContent={['left', 'center']} pt="0">
                            <Paragraph pl={['lg', 0]} size={THEME_CONSTANTS.TEXT_SIZES.MEDIUM} pt="0">
                                <JsxText
                                    variant="dark"
                                    backgroundColor="pink"
                                    path="SignatureBlock.Date"
                                    params={DateTextParams}
                                />
                            </Paragraph>
                        </Flex>
                        <Flex justifyContent="center" py={['xs', 'sm']}>
                            <SubmitButton
                                type="submit"
                                data-qa-id="finish-application-button"
                                onClick={() => {
                                    addNewRelicPageAction('Submit button clicked');
                                }}
                            >
                                <Text path="FinishButtonText" />
                            </SubmitButton>
                        </Flex>
                    </SectionWrap>
                </Box>
            </Section>
        </Box>
    );
};

AgreementSign.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const AgreementSignWithText = withText('Sections.AgreementSign')(AgreementSign);

AgreementSignWithText.displayName = 'AgreementSign';

export default AgreementSignWithText;
