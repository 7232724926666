import { CrossIcon } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { withText } from '../../../text/textStore';
import LinkButton from '../../LinkButton';

export const Container = styled.div`
    ${({ theme }) => theme.components.internalModal.closeButtonContainer}
`;

export const StyledIcon = styled(CrossIcon)`
    ${({ theme }) => theme.components.internalModal.closeButtonIcon}
`;

const CloseButton = forwardRef(({ Text, onClick }, ref) => (
    <Container>
        <LinkButton data-qa-id="modal-close-button" onClick={onClick} ref={ref}>
            <Text path="Close" />
            <StyledIcon role="img" aria-label={Text({ path: 'CloseButtonAriaLabel' })} />
        </LinkButton>
    </Container>
));

CloseButton.propTypes = {
    Text: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
};
CloseButton.displayName = 'UnwrappedCloseButton';

const WrappedCloseButton = withText('Modal')(CloseButton);
WrappedCloseButton.displayName = 'CloseButton';

export default WrappedCloseButton;
