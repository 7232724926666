import { Box, Flex, Text, THEME_CONSTANTS } from '@cof/plastic-components';
import PropType from 'prop-types';

import aggregatorsLogoMap, { aggregatorsExperimentLogoMap } from '../Logo/AggregatorsLogo/aggregatorsLogoMap';
import { useAggregatorBrandExperiment } from '../../hooks/useExperiment/useAggregatorBrandExperiment';
import { useJourneyContext } from '../../data/JourneyContext';

const { COLORS } = THEME_CONSTANTS;

const AggregatorLogo = ({ appForm = false }) => {
    const { aggregatorConsumerName } = useJourneyContext();
    const { aggregatorExperiment, lightMode } = useAggregatorBrandExperiment();

    return (
        <>
            {aggregatorExperiment && appForm ? (
                <Flex data-qa-id="variant-aggregator-logo" alignItems="center" justifyContent="center">
                    <Box pl={['xs', null, 'sm']} pr={[0, null, 'xs']}>
                        <Text
                            size="small"
                            color={lightMode ? COLORS.global.white : COLORS.capitalOne.blue750}
                            aria-hidden
                        >
                            x
                        </Text>
                    </Box>
                    <Box paddingLeft="10px">{aggregatorsExperimentLogoMap[aggregatorConsumerName]}</Box>
                </Flex>
            ) : (
                aggregatorConsumerName && (
                    <Box paddingLeft="10px" data-qa-id="default-aggregator-logo">
                        {aggregatorsLogoMap[aggregatorConsumerName]}
                    </Box>
                )
            )}
        </>
    );
};

AggregatorLogo.propTypes = {
    appForm: PropType.bool
};

export default AggregatorLogo;
